import { Commit } from "~~/vuex";
import { api } from "@/api/Api";
import { accountsQuery } from "@/api/interfaces/accounts/filters";

import { IPayments } from "@/api/interfaces/IApi";
import { CreateRefundDto } from "@/store/interfaces/payments/CreateRefund.dto";

export interface paymentsState {
  paymentsList: IPayments[];
  totalPayments: number;
  userInfo?: IPayments;
}
export const paymentsModule = {
  state(): paymentsState {
    return {
      paymentsList: [],
      totalPayments: 0
    };
  },

  getters: {
    paymentList: ({ paymentsList }: paymentsState): IPayments[] => {
      return paymentsList;
    },
    userPaymentList: ({ paymentsList }: paymentsState): IPayments[] => {
      return paymentsList;
    },
    totalPayment: ({ totalPayments }: paymentsState): number => totalPayments
  },

  mutations: {
    setPayments(state: paymentsState, payments: IPayments[]) {
      state.paymentsList = payments;
    },
    setUserPayments(state: paymentsState, payments: IPayments[]) {
      state.paymentsList = payments;
    },
    setTotalPayments(state: paymentsState, total: number) {
      state.totalPayments = total;
    },
    setTotalUserPayments(state: paymentsState, total: number) {
      state.totalPayments = total;
    }
  },

  actions: {
    async fetchPaymentsList(
      { commit }: { commit: Commit },
      { viewConfig, filter }: { viewConfig: accountsQuery; filter: any }
    ): Promise<IPayments> {
      const res = await api.fetchUserPayments(viewConfig, filter);
      const { data, total } = res;
      if (data) {
        commit("setPayments", data);
      }
      if (total) {
        commit("setTotalPayments", total);
      }
      return res;
    },
    async fetchPaymentHistoryById({ commit }: { commit: Commit }, id: number) {
      const res = await api.fetchPaymentsById(id);
      const { data, total } = res;
      if (data) {
        commit("setUserPayments", data);
      }
      if (total) {
        commit("setTotalUserPayments", total);
      }
      return res;
    },
    async createRefund(
      { commit }: { commit: Commit },
      { data }: { data: CreateRefundDto }
    ) {
      return await api.refundCreate(data);
    }
  }
};
