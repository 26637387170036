import { Commit } from "~~/vuex";
import { api } from "@/api/Api";
import { accountsQuery } from "@/api/interfaces/accounts/filters";

import {
  IQuestions,
  questionRequest
} from "@/api/interfaces/questions/IQuestions";
import { httpMethodType, IApiResponse } from "@/api/interfaces/IApi";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import {
  IFaqCategoriesData,
  IFaqQuestionsData
} from "@/api/interfaces/faq/common";
import { IFetchFaqLocalizationData, IMappedFaqLocalization } from "@/api/services/faq/faqLocalizationsTypes";

export interface faqState {
  questionsList: IQuestions[];
  totalQuestions: number;
  categoriesList: ICategories[];
  totalCategories: number;
}
export const faqModule = {
  state(): faqState {
    return {
      questionsList: [],
      totalQuestions: 0,
      categoriesList: [],
      totalCategories: 0
    };
  },

  getters: {
    questionsList: ({ questionsList }: faqState): IQuestions[] => {
      return [...questionsList];
    },
    totalQuestions: ({ totalQuestions }: faqState): number => totalQuestions,
    categoriesList: ({ categoriesList }: faqState): ICategories[] => {
      return [...categoriesList];
    },
    totalCategories: ({ totalCategories }: faqState): number => totalCategories
  },

  mutations: {
    setQuestions(state: faqState, data: IQuestions[]) {
      state.questionsList = data;
    },
    setTotalQuestions(state: faqState, total: number) {
      state.totalQuestions = total;
    },
    setCategories(state: faqState, data: ICategories[]) {
      state.categoriesList = data;
    },
    setTotalCategories(state: faqState, total: number) {
      state.totalCategories = total;
    }
  },

  actions: {
    async fetchQuestionList(
      { commit }: { commit: Commit },
      { query, id }: { query: accountsQuery; id: number }
    ): Promise<IFaqQuestionsData> {
      const data: IFaqQuestionsData = await api.fetchQuestions(query, id);

      if (data.questions) {
        commit("setQuestions", data.questions);
      }
      commit("setTotalQuestions", data.total);

      return data;
    },

    async addQuestion(
      { commit }: { commit: Commit },
      { data: reqData }: { data: IQuestions }
    ): Promise<IApiResponse> {
      const response: any = await api.addQuestion(reqData);

      return response;
    },

    async editQuestion(
      { commit }: { commit: Commit },
      { data: reqData, file, id }: { data: IQuestions; id: number; file: File }
    ): Promise<IApiResponse> {
      const response: any = await api.editQuestion(reqData, id);

      return response;
    },

    async removeQuestion(
      { commit }: { commit: Commit },
      { id }: { id: number }
    ): Promise<questionRequest> {
      return await api.removeQuestion(id);
    },

    async fetchCategoriesList(
      { commit }: { commit: Commit },
      { query }: { query: accountsQuery }
    ): Promise<IFaqCategoriesData> {
      const data: IFaqCategoriesData = await api.fetchCategories(query);
      if (data.categories) {
        commit("setCategories", data.categories);
      }

      if (data.total) {
        commit("setTotalCategories", data.total);
      }
      return data;
    },

    async addEditCategory(
      { commit }: { commit: Commit },
      {
        data,
        method,
        file,
        id
      }: {
        data: ICategories;
        method: httpMethodType;
        file: File;
        id: number | undefined;
      }
    ): Promise<IApiResponse> {
      return await api.addEditCategory(data, method, file, id);
    },

    async removeCategory(
      { commit }: { commit: Commit },
      { id }: { id: number }
    ): Promise<IApiResponse> {
      return await api.removeCategory(id);
    },

    async fetchFaqLocalizations(
      { commit }: { commit: Commit },
      { faqRecordId, type }: { faqRecordId: number; type: string }
    ) {
      return await api.fetchFaqLocalizations(faqRecordId, type);
    },

    async editFaqLocalization(
      { commit }: { commit: Commit },
      { editData, type }: { editData: IMappedFaqLocalization; type: string }
    ) {
      return await api.editFaqLocalization(editData, type);
    }
  }
};
