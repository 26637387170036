import { apiRequestType, GetQueryParamsLoggerDto } from "@/api/interfaces/IApi";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import moment from "moment";

export const logsService = {
  async fetchLogs(requst: apiRequestType, query: GetQueryParamsLoggerDto) {
    if (query.from === "Invalid date" || query.to === "Invalid date") {
      delete query.from;
      delete query.to;
    }
    const queryString = queryObjectToUrlString({
      ...query,
      to: query?.to
        ? moment(query.to)
            .add(1, "day")
            .subtract(3, "hours")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
        : "",
    });
    const result = await requst(`/logs?${queryString}`, "get");
    return result;
  },

  async removeLogs(
    requst: apiRequestType,
    type: string,
    activeFrom: string,
    activeTo: string
  ) {
    const queryString = queryObjectToUrlString({
      type,
      activeFrom,
      activeTo: moment(activeTo)
        .add(1, "day")
        .subtract(3, "hours")
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
    });
    const result = await requst(`/logs/remove?${queryString}`, "get");
    return result;
  },

  async removeAllLogs(requst: apiRequestType, type: string) {
    const queryString = queryObjectToUrlString({ type });
    const result = await requst(`/logs/remove/all?${queryString}`, "get");
    return result;
  },
};
