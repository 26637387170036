import { Commit } from "~~/vuex";
import { api } from "@/api/Api";
import {
  httpMethodType,
  IApiResponse,
  IBaseQuery,
} from "@/api/interfaces/IApi";
import { IPages, IPagesData } from "@/api/interfaces/pages/IPages";
import { IQuestions } from "@/api/interfaces/questions/IQuestions";
import { IFaqQuestionsData } from "@/api/interfaces/faq/common";

export interface pagesState {
  pagesList: IPages[];
  pagesFaqList: IQuestions[];
  totalPages: number;
  totalPagesFaq: number;
}
export const pagesModule = {
  state(): pagesState {
    return {
      pagesList: [],
      pagesFaqList: [],
      totalPages: 0,
      totalPagesFaq: 0,
    };
  },

  getters: {
    pagesList: ({ pagesList }: pagesState): IPages[] => {
      return pagesList.filter(({ path }: IPages) => (path.length));
    },

    homePageData: ({ pagesList }: pagesState): IPages | undefined => {
      return pagesList.find(({ path }: IPages) => (!path.length));
    },

    pagesH1List: ({ pagesList }: pagesState): Array<{ title: string }> => {
      return pagesList
      .filter(({ path }: IPages) => (path.length))
      .map(({ h1, id }: IPages) => ({ title: h1, id }));
    },

    pagesFaqList: ({ pagesFaqList }: pagesState): IQuestions[] => {
      return [...pagesFaqList];
    },

    totalPages: ({ totalPages }: pagesState): number => totalPages,

    totalPagesFaq: ({ totalPagesFaq }: pagesState): number => totalPagesFaq,
  },

  mutations: {
    setPages(state: pagesState, data: IPages[]) {
      state.pagesList = data;
    },
    setPagesFaq(state: pagesState, data: IQuestions[]) {
      state.pagesFaqList = data;
    },
    setTotalPages(state: pagesState, total: number) {
      state.totalPages = total;
    },
    setTotalPagesFaq(state: pagesState, total: number) {
      state.totalPagesFaq = total;
    },
  },

  actions: {
    async fetchPagesList(
      { commit }: { commit: Commit },
      { query }: { query: IBaseQuery }
    ): Promise<IPagesData> {
      const data: IPagesData = await api.fetchPages(query);
      if (data.success) {
        commit("setPages", data.result);
      }

      if (data.total) {
        commit("setTotalPages", data.total);
      }
      return data;
    },

    async addEditPage(
      { commit }: { commit: Commit },
      {
        data,
        method,
        id,
      }: {
        data: IPages;
        method: httpMethodType;
        id: number | undefined;
      }
    ): Promise<IApiResponse> {
      return await api.addEditPages(data, method, id);
    },

    async removePage(
      { commit }: { commit: Commit },
      { id }: { id: number }
    ): Promise<IApiResponse> {
      return await api.removePages(id);
    },

    async fetchPageFaq(
      { commit }: { commit: Commit },
      { query, id }: { query: IBaseQuery; id: number | null }
    ): Promise<IFaqQuestionsData> {
      const data: IFaqQuestionsData = await api.fetchPagesFaq(query, id);
      if (data.success) {
        commit("setPagesFaq", data.questions);
      }
      if (data.success) {
        commit("setTotalPagesFaq", data.total);
      }
      return data;
    },

    async addEditPageFaq(
      { commit }: { commit: Commit },
      {
        data,
        method,
        id,
      }: {
        data: IPages;
        method: httpMethodType;
        id: number | undefined;
      }
    ): Promise<IApiResponse> {
      return await api.addEditPageFaq(data, method, id);
    },
  },
};
