import { apiRequestType, IApiResponse } from "@/api/interfaces/IApi";
import { IDeviceBody } from "@/store/interfaces/suported-device/IDevice";
import {
  EditDataAbouts,
  IAboutsResponse
} from "@/api/interfaces/about-products/common";
import { IAbout } from "@/store/interfaces/about-product/IAbouts";
import { IMappedAboutLocalization } from "./aboutTypes";

export const aboutProductsService = {
  async fetchAboutsList(
    request: apiRequestType,
    value: string
  ): Promise<IAboutsResponse> {
    const response = await request(`/${value}`, "get");
    const data: IAbout[] = <IAbout[]>response.data;

    return {
      ...response,
      data
    };
  },
  async editAbouts(
    request: apiRequestType,
    value: string,
    editData: EditDataAbouts
  ): Promise<IApiResponse> {
    const response = await request(
      `/${value}/${editData.id}`,
      "patch",
      editData
    );
    const data: IDeviceBody = <IDeviceBody>response.data;
    return {
      ...response,
      data
    };
  },
  async editAboutLocalization(
    request: apiRequestType,
    editData: IMappedAboutLocalization,
    type: string
  ): Promise<IApiResponse> {
    const isEditRecord = editData?.id;
    let url =
      type === "about" ? "/about-localizations" : "/legal-localizations";
    const method = isEditRecord ? "patch" : "post";
    if (isEditRecord) {
      url = url.concat(`/${editData.id}`);
    }
    const response = await request(url, method, editData);
    const data: IAbout = <IAbout>response.data;
    return {
      ...response,
      data
    };
  },
  async fetchAboutLocalizations(
    request: apiRequestType,
    id: number,
    type: string
  ): Promise<IAboutsResponse> {
    const url =
      type === "about"
        ? `/about-localizations/${id}`
        : `/legal-localizations/${id}`;
    const response = await request(url, "get");
    const data: IAbout[] = <IAbout[]>response.data;

    return {
      ...response,
      data
    };
  }
};
