import { Commit } from "~~/vuex";
import { api } from "@/api/Api";
import { accountsQuery } from "@/api/interfaces/accounts/filters";

import {
  esimPlansFaqRequest,
  IEsimPlansFaq,
  IEsimPlansFaqData,
} from "@/api/interfaces/esim-plans-faq/IEsimPlansFaq";
import { IApiResponse } from "@/api/interfaces/IApi";

export interface esimPlansesimPlansFaqState {
  esimPlansFaqList: IEsimPlansFaqData[];
  totalQuestions: number;
}
export const esimPlansFaqModule = {
  state(): esimPlansesimPlansFaqState {
    return {
      esimPlansFaqList: [],
      totalQuestions: 0
    };
  },

  getters: {
    esimPlansFaqList: ({
      esimPlansFaqList
    }: esimPlansesimPlansFaqState): IEsimPlansFaq[] => {
        //@ts-ignore
      return [...esimPlansFaqList];
    },
    totalEsimPlansFaqQuestions: ({ totalQuestions }: esimPlansesimPlansFaqState): number =>
      totalQuestions
  },

  mutations: {
    setEsimPlansFaq(state: esimPlansesimPlansFaqState, data: IEsimPlansFaq[]) {
        //@ts-ignore
      state.esimPlansFaqList = data;
    },
    setEsimPlansTotalQuestions(
      state: esimPlansesimPlansFaqState,
      total: number
    ) {
      state.totalQuestions = total;
    }
  },

  actions: {
    async fetchEsimPlansFaqList(
      { commit }: { commit: Commit },
      { query }: { query: accountsQuery }
    ): Promise<esimPlansFaqRequest> {
      const data: esimPlansFaqRequest = await api.fetchEsimPlansFaqList(query);

      if (data.questions) {
        commit("setEsimPlansFaq", data.questions);
      }
      commit("setEsimPlansTotalQuestions", data.total);

      return data;
    },

    async addEsimPlansFaqQuestion(
      { commit }: { commit: Commit },
      { data: reqData }: { data: IEsimPlansFaq }
    ): Promise<IApiResponse> {
      const response: any = await api.addEsimPlansFaqQuestion(reqData);

      return response;
    },

    async editEsimPlansFaqQuestion(
      { commit }: { commit: Commit },
      {
        data: reqData,
        file,
        id
      }: { data: IEsimPlansFaq; id: number; file: File }
    ): Promise<IApiResponse> {
      const response: any = await api.editEsimPlansFaqQuestion(reqData, id);

      return response;
    },

    async removeEsimPlansFaqQuestion(
      { commit }: { commit: Commit },
      { id }: { id: number }
    ): Promise<IApiResponse> {
      return await api.removeEsimPlansFaqQuestion(id);
    }
  }
};
