import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse
} from "@/api/interfaces/IApi";
import { AddUserDto, EditUserDto } from "@/api/interfaces/users/users.dto";
import {
  accountsFilterSchema,
  accountsQuery
} from "@/api/interfaces/accounts/filters";
import { IAccountsResponse } from "@/api/interfaces/accounts/common";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";

export const usersService = {
  async fetchAccountsService(
    request: apiRequestType,
    query: accountsQuery,
    filter: accountsFilterSchema
  ): Promise<IAccountsResponse> {
    const queryString: string = queryObjectToUrlString(query);

    const response = await request(
      `/users/find?${queryString}`,
      "post",
      filter
    );
    const data: IUserAccount = <IUserAccount>response.data;

    return {
      ...response,
      data
    };
  },

  async fetchUser(
    request: apiRequestType,
    id: string
  ): Promise<IAccountsResponse> {
    const response = await request(`/users/get/${id}`, "get");
    const data: IUserAccount = <IUserAccount>response.data;

    return {
      ...response,
      data
    };
  },

  async fetchAllUsers(request: apiRequestType): Promise<IAccountsResponse> {
    const response = await request(`/users/get/all`, "get");
    const data: IUserAccount = <IUserAccount>response.data;

    return {
      ...response,
      data
    };
  },

  async fetchLoginHistory(
    request: apiRequestType,
    id: string,
    query: accountsQuery
  ): Promise<IAccountsResponse> {
    const queryString: string = queryObjectToUrlString(query);

    const response = await request(`/logins/${id}?${queryString}`, "get");
    const data: IUserAccount = <IUserAccount>response.data;

    return {
      ...response,
      data
    };
  },

  async addUser(
    request: apiRequestType,
    reqData: AddUserDto
  ): Promise<IApiResponse> {
    return await request(`/users/add`, "post", reqData);
  },

  async editUser(
    request: apiRequestType,
    reqData: EditUserDto
  ): Promise<IApiResponse> {
    return await request(`/users/edit`, "patch", reqData);
  },
  async removeUser(
    request: apiRequestType,
    id: number
  ): Promise<IAccountsResponse> {
    const response = await request(`/users/remove/${id}`, "delete");
    const data: IUserAccount = <IUserAccount>response.data;

    return {
      ...response,
      data
    };
  },
  async setActive(
    request: apiRequestType,
    id: number,
    param: boolean
  ): Promise<IAccountsResponse> {
    const response = await request(`/users/verify/${id}`, "patch", {
      emailVerified: param
    });
    const data: IUserAccount = <IUserAccount>response.data;

    return {
      ...response,
      data
    };
  },
  async setBlocked(
    request: apiRequestType,
    id: number,
    param: boolean
  ): Promise<IAccountsResponse> {
    const response = await request(`/users/block/${id}`, "patch", {
      blocked: param
    });
    const data: IUserAccount = <IUserAccount>response.data;

    return {
      ...response,
      data
    };
  },

  async uploadImage(
    fileUpload: apiRequestFileType,
    id: number,
    avatar: File
  ): Promise<IApiResponse> {
    const formData = new FormData();
    formData.append("avatar", avatar);

    const response = await fileUpload(
      `/users/upload-image/${id}`,
      "post",
      avatar,
      "avatar"
    );

    return {
      ...response
    };
  }
};
