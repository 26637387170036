import { Commit } from "vuex";
import { notesQuery } from "@/hooks/notes/useNotes";
import { api } from "@/api/Api";
import { INotes } from "@/api/interfaces/notes/common";
import {
  ChangeStatusNoteDto,
  CreateNoteDto
} from "@/store/interfaces/notes/CreateNote.dto";

interface payload {
  totalNotesHold: number;
  totalNotes: number;
  notesList: INotes[];
  notesHoldList: INotes[];
}

export const notesModule = {
  state(): payload {
    return {
      totalNotes: 0,
      totalNotesHold: 0,
      notesList: [],
      notesHoldList: []
    };
  },
  getters: {
    notesHoldList: ({ notesHoldList }: payload): INotes[] => notesHoldList,
    notesList: ({ notesList }: payload): INotes[] => notesList,
    totalNotes: ({ totalNotes }: payload): number => totalNotes,
    totalNotesHold: ({ totalNotesHold }: payload): number => totalNotesHold
  },
  mutations: {
    setTotalHold(state: payload, total: number) {
      state.totalNotesHold = total;
    },
    setTotal(state: payload, total: number) {
      state.totalNotes = total;
    },
    setNotes(state: payload, notes: INotes[]) {
      state.notesList = notes;
    },
    seHoldNotes(state: payload, notes: INotes[]) {
      state.notesHoldList = notes;
    }
  },
  actions: {
    async fetchNotesList(
      { commit }: { commit: Commit },
      { query, id }: { query: notesQuery; id: number }
    ) {
      const { success, data, total, message } = await api.fetchNotesList(
        query,
        id
      );
      if (data?.length) {
        commit("setNotes", data);
      }
      if (total) {
        commit("setTotal", total);
      }
      return { success, message };
    },
    async fetchHoldNotesList(
      { commit }: { commit: Commit },
      { query, id }: { query: notesQuery; id: number }
    ) {
      const { success, data, total, message } = await api.fetchNotesList(
        {
          ...query,
          hold: true
        },
        id
      );
      if (data?.length) {
        commit("seHoldNotes", data);
      }
      if (total) {
        commit("setTotalHold", total);
      }
      return { success, message };
    },
    async addNotes(
      { commit }: { commit: Commit },
      { id, data }: { id: number; data: CreateNoteDto }
    ) {
      return await api.createNote(id, data);
    },

    async changeStatusNotes(
      { commit }: { commit: Commit },
      {
        id,
        noteId,
        data
      }: { id: number; noteId: number; data: ChangeStatusNoteDto }
    ) {
      return await api.changeStatusNotes(id, noteId, data);
    },
    async removeNote(
      { commit }: { commit: Commit },
      { id, noteId }: { id: number; noteId: number }
    ) {
      return await api.removeNote(id, noteId);
    }
  }
};
