import { User } from "@/store/users/User";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const userImage = require("@/assets/img/Marisa-Tomei.png");

export const userModule = {
  state() {
    return new User(
      "Úrsula Corberó",
      "Operator",
      userImage,
      "ursula.c@outlook.com"
    );
  },

  getters: {
    userInfo: s => {
      return {
        userName: s.name,
        userAvatar: s.avatar,
        userPosition: s.position,
        userEmail: s.email
      };
    }
  }
};
