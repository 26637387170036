import { Commit } from "vuex";
import { IAccessCredentials } from "@/store/interfaces/auth/common";
import { IRefreshTokenResponse } from "@/api/interfaces/ILoginResponse";
import { api } from "@/api/Api";

export async function refreshTokenAction(
  { commit }: { commit: Commit },
  refreshToken: string
): Promise<IAccessCredentials | null> {
  const { success, data }: IRefreshTokenResponse = await api.refreshToken(
    refreshToken
  );

  commit("setCredentials", data);

  return success ? data || null : null;
}
