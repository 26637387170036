<template>
  <form :class="`theme-form ${classnames}`" @submit.prevent="onSubmit">
    <slot />
  </form>
</template>

<script>
export default {
  name: "FormController",
  props: {
    onSubmit: {
      type: Function,
      required: false
    },

    classnames: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
