import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import { categoriesQuery } from "@/api/interfaces/accounts/filters";
import { apiRequestType, IApiResponse } from "@/api/interfaces/IApi";
import {
    IEsimPlansFaq,
  IEsimPlansFaqResponse,
  esimPlansFaqRequest
} from "@/api/interfaces/esim-plans-faq/IEsimPlansFaq";

export const esimPlansFaqService = {
  async fetchEsimPlansFaqList(
    request: apiRequestType,
    query: categoriesQuery,
  ): Promise<esimPlansFaqRequest> {
    const queryString: string = queryObjectToUrlString(query);
    const response = await request(`/about-faq/all?${queryString}`, "get");

    const { questions, total }: IEsimPlansFaqResponse = <IEsimPlansFaqResponse>(
      response.data
    );

    return {
      ...response,
      questions,
      total
    };
  },

  async addEsimPlansFaqQuestion(
    request: apiRequestType,
    data: IEsimPlansFaq
  ): Promise<IApiResponse> {
    return await request(`/about-faq`, "post", data);
  },

  async editEsimPlansFaqQuestion(
    request: apiRequestType,
    data: IEsimPlansFaq,
    id: number
  ): Promise<IApiResponse> {
    return await request(`/about-faq/${id}`, "patch", data);
  },

  async removeEsimPlansFaqQuestion(
    request: apiRequestType,
    id: number
  ): Promise<IApiResponse> {
    return await request(`/about-faq/${id}`, "delete");
  }
};
