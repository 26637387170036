import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  apiRequestFileType,
  apiRequestType,
  httpMethodType,
  IApiResponse,
  IBaseQuery,
} from "@/api/interfaces/IApi";
import {
  IPages,
  IPagesData,
  IPagesResponse,
} from "@/api/interfaces/pages/IPages";
import { IFaqQuestionsData, IFaqQuestionsResponse } from "@/api/interfaces/faq/common";

export const pagesService = {
  async fetchPages(
    request: apiRequestType,
    query: IBaseQuery
  ): Promise<IPagesData> {
    const queryString: string = queryObjectToUrlString(query);
    const response: IApiResponse = await request(
      `/page/get-all?${queryString}`,
      "get"
    );
    const { result, total }: IPagesResponse = <IPagesResponse>response.data;
    return {
      ...response,
      result,
      total,
    };
  },

  async actionWithPage(
    request: apiRequestFileType,
    data: IPages,
    method: httpMethodType,
    id: number | undefined
  ): Promise<IApiResponse> {
    return await request(
      `/page${id ? "/" + id : ""}`,
      method,
      undefined,
      "image",
      data
    );
  },

  async removePage(request: apiRequestType, id: number): Promise<IApiResponse> {
    const response = await request(`/page/${id}`, "delete");
    const data: IPages = <IPages>response.data;

    return {
      ...response,
      data,
    };
  },

  async fetchPagesFaq(
    request: apiRequestType,
    query: IBaseQuery,
    pageId: number | null
  ): Promise<IFaqQuestionsData> {
    const queryString: string = queryObjectToUrlString(query);
    const response: IApiResponse = await request(
      `/page/page-questions/${pageId}?${queryString}`,
      "get"
    );
    const data: IFaqQuestionsResponse = <IFaqQuestionsResponse>response.data;
    return {
      ...response,
      questions: data?.questions,
      total: data?.total,
    };
  },

  async actionWithPageFaq(
    request: apiRequestType,
    data: IPages,
    method: httpMethodType,
    id: number | undefined
  ): Promise<IApiResponse> {
    return await request(
      `/page/question${id ? "/" + id : ""}`,
      method,
      data
    );
  }
};
