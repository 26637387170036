import { getId } from "@/utills/getId";

export function User(name, position = null, avatar, email) {
  this.name = name;
  this.position = position;
  this.avatar = avatar;
  this.email = email;
  this.id = getId("user");

  return this;
}
