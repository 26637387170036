import { Commit } from "~~/vuex";
import { api } from "@/api/Api";
import {
  IBaseQuery,
} from "@/api/interfaces/IApi";
import { ILocalizations, ILocalizationsData } from "@/api/interfaces/localizations/ILocalizations";

export interface localizationsState {
  localizations: ILocalizations[];
  totalLocalizations: number;
}
export const localizationsModule = {
  state(): localizationsState {
    return {
      localizations: [],
      totalLocalizations: 0,
    };
  },

  getters: {
    localizations: ({ localizations }: localizationsState): ILocalizations[] => {
      return localizations;
    },

    totalLocalizations: ({ totalLocalizations }: localizationsState): number => totalLocalizations,
  },

  mutations: {
    setLocalizations(state: localizationsState, data: ILocalizations[]) {
      state.localizations = data;
    },
    setTotalLocalizations(state: localizationsState, total: number) {
      state.totalLocalizations = total;
    }
  },

  actions: {
    async fetchLocalizations(
      { commit }: { commit: Commit },
      { query }: { query: IBaseQuery }
    ): Promise<ILocalizationsData> {
      const data: ILocalizationsData = await api.fetchLocalizations(query);
      if (data.success) {
        commit("setLocalizations", data.results);
      }

      if (data.total) {
        commit("setTotalLocalizations", data.total);
      }
      return data;
    },

  },
};
