import {
  apiRequestType,
  axiosRequest,
  IApiResponse,
  IBaseQuery
} from "@/api/interfaces/IApi";
import {
  AddStripeAccountDto,
  IStripeAccountsResponse
} from "@/api/interfaces/stripe-accounts/IStripeAccounts";
import {
  IStripeAccountsBody,
  IUsedStripeAccountsData
} from "@/store/interfaces/stripe-accounts/IStripeAccounts";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";

export const stripeAccountsService = {
  async fetchStripeAccountsList(
    request: apiRequestType,
    query: IBaseQuery
  ): Promise<IStripeAccountsResponse> {
    const queryString: string = queryObjectToUrlString(query);
    const response = await request(`/stripe-accounts?${queryString}`, "get");
    const data: IStripeAccountsBody = <IStripeAccountsBody>response.data;

    return {
      ...response,
      data
    };
  },
  async addStripeAccount(
    request: apiRequestType,
    body: AddStripeAccountDto
  ): Promise<IApiResponse> {
    const response = await request("/stripe-accounts", "post", body);
    const data: IStripeAccountsBody = <IStripeAccountsBody>response.data;

    return {
      ...response,
      data
    };
  },
  async removeStripeAccount(
    request: apiRequestType,
    id: number
  ): Promise<IApiResponse> {
    const response = await request(`/stripe-accounts/${id}`, "delete");
    const data: IStripeAccountsBody = <IStripeAccountsBody>response.data;

    return {
      ...response,
      data
    };
  },
  async editStripeAccount(
    request: apiRequestType,
    body: AddStripeAccountDto,
    id: number
  ): Promise<IApiResponse> {
    const response = await request(`/stripe-accounts/${id}`, "patch", body);
    const data: IStripeAccountsBody = <IStripeAccountsBody>response.data;
    return {
      ...response,
      data
    };
  },
  async restartPaymentServer(request: apiRequestType) {
    const response = await request(
      "/stripe-accounts/restart-pay-server",
      "get"
    );
    const data = response.data;
    return {
      ...response,
      data
    };
  },
  async getUsedStripeAccounts(request: axiosRequest) {
    const response = await request(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/pay/check-stripe-accounts`,
      "post",
      { access_token: process.env.VUE_APP_HARD_CODE_RESTART_SERVER_KEY }
    );
    const data: IUsedStripeAccountsData = <IUsedStripeAccountsData>(
      response.data
    );

    return {
      ...response,
      data
    };
  }
};
