import { Commit } from "vuex";
import { api } from "@/api/Api";
import { IApiResponse, IBaseQuery } from "@/api/interfaces/IApi";
import { IStripeAccount } from "./interfaces/stripe-accounts/IStripeAccounts";
import {
  AddStripeAccountDto,
  IStripeAccountsResponse
} from "@/api/interfaces/stripe-accounts/IStripeAccounts";

export interface stripeAccountsState {
  stripeAccounts: IStripeAccount[];
  stripeAccountsTotal: number;
}
export const stripeAccountsModule = {
  state(): stripeAccountsState {
    return {
      stripeAccounts: [],
      stripeAccountsTotal: 0
    };
  },

  getters: {
    stripeAccounts: ({
      stripeAccounts
    }: stripeAccountsState): IStripeAccount[] => {
      return [...stripeAccounts];
    },

    stripeAccountsTotal: ({
      stripeAccountsTotal
    }: stripeAccountsState): number => {
      return stripeAccountsTotal;
    }
  },

  mutations: {
    setStripeAccounts(
      state: stripeAccountsState,
      stripeAccounts: IStripeAccount[]
    ) {
      state.stripeAccounts = stripeAccounts;
    },
    setStripeAccountsTotal(state: stripeAccountsState, total: number) {
      state.stripeAccountsTotal = total;
    }
  },

  actions: {
    async restartPaymentServer({ commit }: { commit: Commit }) {
      return await api.restartPaymentServer();
    },
    async fetchStripeAccountsList(
      { commit }: { commit: Commit },
      { query }: { query: IBaseQuery }
    ): Promise<IStripeAccountsResponse> {
      const response: IStripeAccountsResponse = await api.fetchStripeAccountsList(
        query
      );
      const { data } = response;
      if (data?.results) {
        let mappdeData;
        const { data: usedStripeAccounts } = await api.getUsedStripeAccounts();
        if (
          usedStripeAccounts?.usedAccounts &&
          usedStripeAccounts.usedAccounts.length
        ) {
          const accounts = usedStripeAccounts.usedAccounts.map(
            (e: any) => e.title
          );
          mappdeData = data.results.map((ac: any) => {
            return {
              ...ac,
              isUsed: accounts.includes(ac.title)
            };
          });
        } else {
          mappdeData = data.results;
        }
        commit("setStripeAccountsTotal", data.total);
        commit("setStripeAccounts", mappdeData);
      }

      return response;
    },
    async addStripeAccount(
      { commit }: { commit: Commit },
      { data }: { data: AddStripeAccountDto }
    ): Promise<IApiResponse> {
      const response: IApiResponse = await api.addStripeAccount(data);

      return response;
    },

    async editStripeAccount(
      { commit }: { commit: Commit },
      { data, id }: { data: AddStripeAccountDto; id: number }
    ): Promise<IApiResponse> {
      const response: IApiResponse = await api.editStripeAccount(id, data);

      return response;
    },

    async removeStripeAccount(
      { commit }: { commit: Commit },
      { id }: { id: number }
    ): Promise<IApiResponse> {
      const response: IApiResponse = await api.removeStripeAccount(id);

      return response;
    }
  }
};
