<template>
  <ul class="d-flex align-items-center header-toolbar">
    <li class="header-toolbar-item">
      <ToolbarItem icon="clock" />
    </li>
    <li class="header-toolbar-item">
      <ToolbarItem icon="notifications" :indicator-count="notificationsCount" />
    </li>

    <li class="header-toolbar-item">
      <ToolbarItem icon="settings" />
    </li>
  </ul>
</template>

<script>
import ToolbarItem from "@/components/Toolbar/ToolbarItem";
export default {
  name: "HeaderToolbar",
  components: { ToolbarItem },
  data() {
    return {
      notificationsCount: 1
    };
  }
};
</script>

<style scoped></style>
