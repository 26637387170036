T<template>
  <span class="toolbar-button" @click.prevent="onClick">
    <svg-icon :icon="icon" />
    <span class="toolbar-button-indicator">
      <Indicator v-if="indicatorCount > 0" :count="indicatorCount" />
    </span>
  </span>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
import Indicator from "@/components/ui/Indicator";

export default {
  name: "ToolbarItem",
  components: { Indicator, SvgIcon },
  props: {
    indicatorCount: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      required: true
    },

    onClick: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => () => {}
    }
  }
};
</script>

<style lang="scss">
.toolbar-button {
  $indent: 0.75em;
  font-size: 1.42rem;
  position: relative;
  padding: 0 $indent;
  min-width: max-content;
  display: block;
  cursor: pointer;
  @media (any-hover: hover) {
    transition: $transition;
    &:hover {
      transform: scale(1.2);
    }
  }

  .toolbar-button-indicator {
    position: absolute;
    top: 0;
    right: $indent;
    width: max-content;
    height: max-content;
    transform: translate(10%, 0);
    &:before {
      content: "";
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border-radius: 50%;
      background-color: #fff;
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .indicator {
      position: relative;
      z-index: 2;
    }
  }
}
</style>
