import { apiRequestType } from "@/api/interfaces/IApi";
import { IUserDetailsResponse } from "@/api/interfaces/users/IUserDetailsResponse";
import { IUserDetailsData } from "@/store/interfaces/users/IUserShortData";

export async function fetchUserDataService(
  request: apiRequestType,
  userId: number
): Promise<IUserDetailsResponse> {
  const response = await request(`/users/get/${userId}`, "get");
  const data: IUserDetailsData = <IUserDetailsData>response.data;

  return {
    ...response,
    data: {
      ...data,
      type: data.type
    }
  };
}
