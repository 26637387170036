
import Layout from "@/components/Layout/Layout.vue";
import SvgSprite from "@/components/ui/SvgSprite.vue";
import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "vue";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
type AppData = {
  currentRoute: string;
};

export default defineComponent({
  components: { SvgSprite, Layout },

  data() {
    return {
      currentRoute: this.$route.path
    } as AppData;
  },

  computed: {
    ...mapGetters({
      currentUserSummary: "currentUserData",
      appLoading: "appLoading",
      authorized: "authorized"
    }),

    isLoginRoute(): boolean {
      return this.currentRoute === "/login";
    }
  },

  watch: {
    appLoading: {
      handler(loading: boolean) {
        const body = document.body;
        loading
          ? disableBodyScroll(body, {
              reserveScrollBarGap: true
            })
          : enableBodyScroll(body);
      },
      immediate: true
    },

    $route({ path }) {
      this.currentRoute = path;
    },

    authorized(authorized) {
      if (!authorized) {
        this.$router.push({ path: "/login" });
      }
    }
  },

  mounted() {
    this.currentRoute = this.$route.path;
  },

  async created() {
    this.setLoading(true);
    await this.$store.dispatch("fetchUserData");

    setTimeout(() => {
      if (this.appLoading) {
        this.setLoading(false);
      }
    }, 5000);
  },

  methods: {
    ...mapActions({
      showErrorNotification: "showErrorNotification"
    }),

    setLoading(loading: boolean) {
      this.$store.commit("setAppLoading", loading);
    }
  }
});
