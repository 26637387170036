import { paymentRequest, IPayments, axiosRequest } from "@/api/interfaces/IApi";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";

import { accountsQuery } from "@/api/interfaces/accounts/filters";
import { CreateRefundDto } from "@/store/interfaces/payments/CreateRefund.dto";

export const paymentsService = {
  async fetchPayments(
    request: paymentRequest,
    query: accountsQuery,
    filter: any
  ): Promise<IPayments> {
    const queryString: string = queryObjectToUrlString(query);
    const queryString1: string = queryObjectToUrlString(filter);
    const response = await request(
      `/payment-history/all?${queryString + "&" + queryString1}`,
      "get"
    );

    const data: IPayments = <IPayments>response.data;
    return {
      ...response,
      data
    };
  },

  async fetchUserPayments(
    request: paymentRequest,
    id: number
  ): Promise<IPayments> {
    const response = await request(
      `/payment-history/${id}?status=All&page=1&perPage=6`,
      "get"
    );
    const data: IPayments = <IPayments>response.data;

    return {
      ...response,
      data
    };
  },

  async createRefund(request: axiosRequest, data: CreateRefundDto) {
    return await request(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/pay/refund/create/all`,
      "post",
      data
    );
  }
};
