import { Commit } from "vuex";
import { api } from "@/api/Api";
import {
  httpMethodType,
  IApiResponse,
  IBaseQuery,
} from "@/api/interfaces/IApi";

import { IAboutLocation, IAboutLocationResponse } from "@/api/interfaces/about-locations/aboutLocation";

export interface aboutLocationState {
  aboutLocation: IAboutLocation;
}
export const aboutLocationModule = {
  state(): aboutLocationState {
    return {
      aboutLocation: {} as IAboutLocation,
    };
  },

  getters: {
    aboutLocation: ({ aboutLocation }: aboutLocationState): IAboutLocation => {
      return aboutLocation;
    },

  },
  mutations: {
    setAboutLocation(state: aboutLocationState, data: IAboutLocation) {
      state.aboutLocation = data;
    },
  },

  actions: {
    async fetchAboutLocation(
      { commit }: { commit: Commit }
    ): Promise<IAboutLocationResponse> {
      const response: IAboutLocationResponse = await api.fetchAboutLocation();
      if (response.success) {
        commit("setAboutLocation", response.data);
      }

      return response;
    },

    async addEditAboutLocation(
      { commit }: { commit: Commit },
      {
        data,
        method,
        id,
      }: {
        data: IAboutLocation;
        method: httpMethodType;
        id: number | undefined;
      }
    ): Promise<IApiResponse> {
      return await api.addEditAboutLocation(data, method, id);
    },
  }
}