export const regions = {
  state() {
    return {
      regions: [
        {
          name: "Asia",
          id: "as"
        },
        {
          name: "Middle East",
          id: "m-easrt"
        },
        {
          name: "Australia & New Zeland",
          id: "aus"
        },
        {
          name: "European Union",
          id: "eu"
        }
      ]
    };
  },
  getters: {
    regions: state => {
      return state.regions;
    }
  }
};
