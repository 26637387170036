import {
  ICarriersCountry,
  IFetchCountriesDTO
} from "@/api/services/packages/esim/carriers/countries/carriersCountriesTypes";
import { Commit } from "vuex";
import { api } from "@/api/Api";

export interface ICarriersCountriesState {
  countries: ICarriersCountry[];
}

const initialState = {
  countries: []
};

export const carrirsCountries = {
  state(): ICarriersCountriesState {
    return { ...initialState };
  },

  getters: {
    getCountries({ countries }: ICarriersCountriesState): ICarriersCountry[] {
      return countries;
    }
  },

  mutations: {
    setCountries(
      state: ICarriersCountriesState,
      countries: ICarriersCountry[]
    ) {
      state.countries = countries;
    }
  },

  actions: {
    async fetchCountries(
      { commit }: { commit: Commit },
      params: IFetchCountriesDTO
    ) {
      const { data, ...response } = await api.fetchCarriersCountries(params);

      commit("setCountries", data);

      return response;
    }
  }
};
