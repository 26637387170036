import { apiRequestType } from "@/api/interfaces/IApi";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  IFetchCarriersCountriesResponse,
  ICarriersCountry,
  IFetchCountriesDTO
} from "@/api/services/packages/esim/carriers/countries/carriersCountriesTypes";

export const carriersCountriesService = {
  async fetchCountries(
    request: apiRequestType,
    params: IFetchCountriesDTO
  ): Promise<IFetchCarriersCountriesResponse> {
    const query = queryObjectToUrlString(params);

    const response = await request(`/carriers/countries-all?${query}`, "get");
    const data: ICarriersCountry[] =
      <ICarriersCountry[]>response?.data?.results ?? [];

    return { ...response, data };
  }
};
