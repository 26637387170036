import { apiStatus, IApiResponse } from "@/api/interfaces/IApi";
import { getErrorByStatusCode } from "@/api/contants/serverErrors";

export class ApiResponse implements IApiResponse {
  public success: boolean;
  public message: string;
  public status: apiStatus;
  public data: object | null;
  public total: number | null;

  constructor(
    success: boolean,
    code = 0,
    data: object | null = null,
    total?: number | undefined
  ) {
    this.success = success;
    this.message = getErrorByStatusCode(code.toString());
    this.status = { code };
    this.data = data;
    this.total = total ?? null;
  }
}
