import { IBrands } from "@/api/interfaces/brands/IBrands";
import { apiRequestType, IApiResponse } from "@/api/interfaces/IApi";

export const esimProfilesService = {
  async fetchEsimProfilesList(
    request: apiRequestType,
    id: number
  ): Promise<IApiResponse> {
    const response = await request(`esim/active/byuser/${id}`, "get");
    const data: IBrands = <IBrands>response.data;

    return {
      ...response,
      data
    };
  }
};
