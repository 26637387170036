import { apiRequestType } from "@/api/interfaces/IApi";
import {
  ILoginResponse,
  loginResponseDataType
} from "@/api/interfaces/ILoginResponse";
import { ILoginCredentials } from "@/store/interfaces/auth/common";

export async function loginService(
  request: apiRequestType,
  credentials: ILoginCredentials
): Promise<ILoginResponse> {
  const response = await request("/auth/login", "post", {
    ...credentials,
    client: "ios",
    device: "iPhone 11 Pro"
  });

  const data: loginResponseDataType = <loginResponseDataType>response.data;

  return {
    ...response,
    data
  };
}
