import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_sprite = _resolveComponent("svg-sprite")!
  const _component_vue_notification_list = _resolveComponent("vue-notification-list")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_svg_sprite),
    _createVNode(_component_vue_notification_list, { class: "notification-list--bottom-right" }),
    (_ctx.isLoginRoute)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createBlock(_component_Layout, {
          key: 1,
          loading: _ctx.appLoading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }, 8, ["loading"]))
  ], 64))
}