import { Commit, Dispatch } from "~~/vuex";
import { api } from "@/api/Api";
import { GetQueryParamsLoggerDto } from "@/api/interfaces/IApi";
import { ILogs } from "@/store/interfaces/logs/logs";

interface State {
  logs: ILogs[];
  total: number;
}
export const logs = {
  state(): State {
    return { logs: [], total: 0 };
  },

  mutations: {
    setLogs(state: State, data: State): void {
      state.logs = data.logs;
      state.total = data.total;
    },
  },

  getters: {
    getLogs(state: State): ILogs[] {
      return state.logs;
    },

    getTotalLogs(state: State): number {
      return state.total;
    },
  },

  actions: {
    async fetchLogs(
      { commit }: { commit: Commit },
      {
        viewConfiguration,
      }: { viewConfiguration: GetQueryParamsLoggerDto; value: string }
    ) {
      const { data, status, message } = await api.fetchLogs(viewConfiguration);
      commit("setLogs", { logs: data.result, total: data.total });
      return { status, message };
    },

    async removeLogs(
      _: any,
      {
        value,
        activeFrom,
        activeTo,
      }: { value: string; activeFrom: string; activeTo: string }
    ) {
      const { status, message } = await api.removeLogs(
        value,
        activeFrom,
        activeTo
      );
      return { status, message };
    },

    async removeAllLogs(_: any, { value }: { value: string }) {
      const { status, message } = await api.removeAllLogs(value);
      return { status, message };
    },
  },
};
