export const statusCodes = {
  tokenDied: 208,
  loggedOnAnotherDevice: 240,
  success: 200,
  wrongToken: 192,
  credentialsNotValid: 117,
  accountBlocked: 188,
  error_occurred_when_login: 189,
  is_already_exist: 190,
  user_create_error: 242,
  code_expired_or_invalid: 218,
  email_is_not_verified: 209
};

export const reversedStatusCodes = Object.fromEntries(
  Object.entries(statusCodes).map(([key, value]) => [value.toString(), key])
);

// use when needed

// UserNotFound = 116,
//   CredentialsNotValid = 117,
//   AccountBlocked = 188,
//   LoginError = 189,
//   IsAlreadyExist = 190,
//   DataIsNotFound = 191,
//   WrongToken = 192,
//   InsufficientMinutes = 193,
//   InvalidData = 194,
//   OperationFailed = 195,
//   DataIsNotAvailable = 196,
//   InsufficientBalance = 197,
//   StripeWebhookError = 198,
//   AwsError = 199,
//   EsimError = 201,
//   MultitelError = 202,
//   ActionIsNotSupported = 203,
//   NotEnoughPermissions = 204,
//   Timeount60Sec = 205,
//   PhoneIsAlreadyExist = 206,
//   TimeoutExpired = 207,
//   Unauthorized = 208,
//   EmailIsNotVerified = 209,
//   UnknownPBX = 210,
//   UnknownSourceNumber = 211,
//   UnknownTargetNumber = 212,
//   UnknownMtCallId = 213,
//   RequestOtherUserData = 214,
//   RefundRequestAlreadyExists = 215,
//   WrongRefundRequestStatus = 216,
//   UrlNotFound = 217,
//   CodeExpiredOrInvalid = 218,
//   TwilioError = 219,
//   'Validation failed (numeric string is expected)' = 220,
//   AlreadyInUse = 221,
//   FaqCategoryNotExist = 222,
//   FaqAlreadyExist = 223,
//   IncorrectRequestType = 224,
//   FaqQuestionNotFound = 225,
//   IsNotBlank = 226,
//   InternalError = 227,
//   ImageIsNotValid = 228,
//   CustomerNotFound = 229,
//   IntentNotFound = 230,
//   PaymentMehodNotFound = 231,
//   RefundNotFound = 232,
//   StripeCustomerError = 233,
//   StripeChargeError = 234,
//   StripeIntentError = 235,
//   StripeRefundError = 236,
//   StripeTokenError = 237,
//   StripeOrderError = 238,
//   UnexpectedJSON = 239,
//   SignedInFromAnotherDevice = 240,
//   CardAndTokenError = 241,
//   UserCreateError = 242,
//   SendPushNotificationError = 243,
//   HistoryCreateError = 244,
//   AppleEmailIsPrivate = 245,
//   SendLetterError = 246,
//   PayPalCreateOrderError = 247,
//   PayPalCaptureOrderError = 248,
//   EsimSynchronizeError = 249,
//   EsimTypesCreateError = 250,
//   EsimTypesError = 251,
//   EsimTypesDeleteError = 252,
//   EsimTypesUpdateError = 253,
//   ImportICCIDError = 254,
//   ImportMSISDNrror = 255,
//   ImportIMSIrror = 256,
//   ImportESIM_QR_CODError = 257,
//   ImportI_ACCOUNTerror = 258,
//   EsimServerCreateError = 259,
//   EsimServerUpdateError = 260,
//   EsimServerGetError = 261,
//   EsimServerIsNotEmpty = 262,
//   EsimTypeIsNotEmpty = 263,
//   RegionIsNotEmpty = 264,
//   SubRegionIsNotEmpty = 265,
//   OperatorsNotFound = 266,
//   UpdateEsimExpirationFailed = 267,
//   BundleNotCorrect = 268,

//
// "unknown_error" = "Unknown error";
// "user_not_found" = "User not found";
// "email_or_password_is_wrong" = "Email or password is wrong";
// "user_account_was_blocked" = "User account was blocked";
// "error_occurred_when_login" = "Error occurred when login";
// "is_already_exist" = "Is already exist";
// "data_is_not_found" = "Data is not found";
// "incorrect_token" = "Incorrect token";
// "insufficient_minutes" = "Insufficient minutes";
// "invalid_data" = "Invalid data";
// "operation_failed" = "Operation failed";
// "data_is_not_available" = "Data is not available";
// "insufficient_balance" = "Insufficient balance";
// "error_occurred_when_stripe_webhook_on_server_side" = "Error occurred when stripe WebHook on server side";
// "error_occurred_with_aws_on_server_side" = "Error occurred with Aws on server side";
// "error_occurred_with_esim_on_server_side" = "Error occurred with Esim on server side";
// "error_occurred_with_multitel_on_server_side" = "Error occurred with Multitel on server side";
// "action_is_not_supported" = "Action is not supported";
// "not_enough_permissions" = "Not enough permissions";
// "try_action_again_after_60_seconds" = "Try action again after 60 seconds";
// "mail_is_already_exist" = "Mail is already exist";
// "timeout_is_expired" = "Timeout is expired";
// "unauthorized" = "Unauthorized";
// "email_is_not_verified" = "Email is not verified";
// "unknown_pbx" = "Unknown PBX";
// "unknown_source_number" = "Unknown source number";
// "unknown_target_number" = "Unknown target number";
// "unknown_mt_call_id" = "Unknown Mt call id";
// "cant_request_other_user_data" = "Can't request other user data";
// "refund_request_already_exist" = "Refund request already exist";
// "wrong_refund_request_status" = "Wrong refund request status";
// "url_not_found" = "Url not found";
// "the_code_you_entered_is_incorrect" = "The code you entered is incorrect";
// "error_occurred_with_twilio_on_server_side" = "Error occurred with Twilio on server side";
// "validation_failed" = "Validation failed";
// "this_email_is_already_in_use" = "This email is already in use";
// "validation_failed_on_server_side" = "Validation failed on server side";
// "error_occurred_with_location_on_server_side" = "Error occurred with location on server side";
// "checking_value_failed_on_server_side" = "Checking value failed on server side";
// "error_occurred_with_value_operations_on_server_side" = "Error occurred with value operations on server side";
// "identifier_error" = "Identifier error";
// "error_occurred_with_working_array_on_server_side" = "Error occurred with working array on server side";
// "wrong_token_error_text" = "Warning! Have you logged in from another device? If you haven’t, please log in and change your password.";
