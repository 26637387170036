export const oldCountries = {
  state() {
    return [
      {
        id: "esp",
        name: "Spain",
        icon: require("@/assets/img/countries/esp.svg")
      },
      {
        id: "arg",
        name: "Argentina",
        icon: require("@/assets/img/countries/arg.svg")
      },
      {
        id: "aus",
        name: "Australia",
        icon: require("@/assets/img/countries/aus.svg")
      },
      {
        id: "bra",
        name: "Brazil",
        icon: require("@/assets/img/countries/bra.svg")
      },
      {
        id: "can",
        name: "Canada",
        icon: require("@/assets/img/countries/can.svg")
      },
      {
        id: "ch",
        name: "China",
        icon: require("@/assets/img/countries/ch.svg")
      },
      {
        id: "chl",
        name: "Chile",
        icon: require("@/assets/img/countries/chl.svg")
      },
      {
        id: "col",
        name: "Columbia",
        icon: require("@/assets/img/countries/col.svg")
      },
      {
        id: "de",
        name: "Germany",
        icon: require("@/assets/img/countries/de.svg")
      },
      {
        id: "en",
        name: "Great Britain",
        icon: require("@/assets/img/countries/en.svg")
      },
      {
        id: "fr",
        name: "France",
        icon: require("@/assets/img/countries/fr.svg")
      }
    ];
  },

  getters: {
    countries: state => state,
    country: (state, id) => state.filter(country => country.id === id)
  }
};
