import { IEsimDirectoryDefault } from "./interfaces/esim-directory/common";
import { number } from "@/validator/validators/number";
import { PromoCodesDto } from "./interfaces/promo-codes/promo-codes.dto";
import {
  httpMethodType,
  httpType,
  IApi,
  IApiResponse,
  IBaseQuery,
  IPayments,
  GetQueryParamsLoggerDto
} from "@/api/interfaces/IApi";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ILoginCredentials } from "@/store/interfaces/auth/common";
import { ApiResponse } from "@/api/helpers/Response";
import {
  ILoginResponse,
  IRefreshTokenResponse
} from "@/api/interfaces/ILoginResponse";
import { $axios } from "@/plugins/$axios";
import { loginService } from "@/api/services/auth/login";
import { refreshTokenService } from "@/api/services/auth/refreshToken";
import { IUserDetailsResponse } from "@/api/interfaces/users/IUserDetailsResponse";
import { fetchUserDataService } from "@/api/services/user/fetchUserData";
import {
  accountsFilterSchema,
  accountsQuery
} from "@/api/interfaces/accounts/filters";
import { IAccountsResponse } from "@/api/interfaces/accounts/common";
import { usersService } from "@/api/services/accounts/users.service";
import { paymentsService } from "@/api/services/payments/payment.service";
import { templatesService } from "./services/templates/template.service";
import {
  templatesData,
  templatesQuery
} from "@/api/interfaces/templates/common";
import {
  ITemplatesDataResponse,
  ITemplatesTypesDataResponse
} from "@/api/interfaces/templates/ITemplatesResponseData";
import { AddUserDto, EditUserDto } from "@/api/interfaces/users/users.dto";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { suportedDevicesService } from "./services/suported-devices/suported-devices.service";
import {
  IBrandsResponse,
  IDevicesResponse
} from "./interfaces/suported-devices/common";
import { IBrandsQuery } from "@/store/interfaces/suported-device/IDevice";
import { appStorage } from "@/storage/storage";
import { AddDeviceDto } from "./interfaces/suported-devices/devices.dto";
import {
  EditDataAbouts,
  IAboutsResponse
} from "./interfaces/about-products/common";
import { aboutProductsService } from "./services/about-products/about-products";
import {
  fetchLocationPackagesDto,
  fetchLocationPackagesRatesDto,
  IRemoveLocationPackageDto,
  IUpdateLocationPackageDto,
  IZone
} from "@/api/services/packages/esim/location-packages/types";
import {
  IFetchLocationPackagesRatesResponse,
  IFetchLocationPackagesResponse
} from "@/api/services/packages/esim/location-packages/responseTypes";
import { locationPackageService } from "@/api/services/packages/esim/location-packages/locationPackages";
import { esimDirectoryService } from "@/api/services/packages/esim/directory/esimDirectoryService";
import {
  IFetchCarriersCountriesResponse,
  IFetchCountriesDTO
} from "@/api/services/packages/esim/carriers/countries/carriersCountriesTypes";
import { carriersCountriesService } from "@/api/services/packages/esim/carriers/countries/carriersCountriesService";
import { carriersService } from "@/api/services/packages/esim/carriers/carriersService";
import {
  IFetchEsimDirectoryLocalizationsResponse,
  IFetchEsimZonesResponse,
  IFetchNetworkGeolocationResponse,
  IFetchRates
} from "@/api/services/packages/esim/directory/responseTypes";
import {
  IFetchCarriersResponse,
  IUpdateCarrierDto
} from "@/api/services/packages/esim/carriers/types";
import { brandsService } from "./services/brands/brands.service";
import { IBrands } from "./interfaces/brands/IBrands";
import { faqService } from "@/api/services/faq/faq.service";
import {
  IFaqCategoriesData,
  IFaqQuestionsData
} from "@/api/interfaces/faq/common";
import { IQuestions } from "@/api/interfaces/questions/IQuestions";
import { CreateRefundDto } from "@/store/interfaces/payments/CreateRefund.dto";
import {
  IFetchSelectedLocationsDto,
  locationsService
} from "@/api/services/locations/locationsService";
import {
  ICreateLocationDto,
  IDeleteLocationDto,
  IFetchLocationFaqListResponse,
  IFetchLocationLocalizationResponse,
  IFetchLocationsDto,
  IFetchLocationsResponse,
  ILocationFaqList,
  IUploadLocationImageDto,
  selectedLocationListType,
  selectedLocationType
} from "@/api/services/locations/locationTypes";
import {
  TPackageLocation,
  TPackageLocationSingle
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { profilesService } from "@/api/services/profiles/profiles.service";
import { TLocationKeys } from "@/store/locations";
import {
  fetchEsimProfilesDto,
  fetchEsimProviderDto
} from "@/api/services/profiles/profiles.types";
import { plansService } from "@/api/services/plans/plans.service";
import { editEsimPlanEsimServerDto } from "@/hooks/esim-plans/modules/updatePlanEsimServer";
import { notesQuery } from "@/hooks/notes/useNotes";
import { notesService } from "@/api/services/notes/notesService";
import {
  ChangeStatusNoteDto,
  CreateNoteDto
} from "@/store/interfaces/notes/CreateNote.dto";
import { esimProfilesService } from "@/api/services/esim/profile/profile.service";
import { promoCodesSevice } from "./services/promo-codes/promoCodes.service";
import { IApiPromoResponse } from "./interfaces/promo-codes/common";
import { IRate } from "@/api/interfaces/rates/common";
import { UpdatePlanDiscountDto } from "@/hooks/esim-plans/types/useEsimPlansListType";
import { logsService } from "@/api/services/logs/logs.service";
import { balancesService } from "@/api/services/balances/balances.service";
import {
  balancesHistoryData,
  userBalanceData
} from "@/api/interfaces/balances/common";
import { fetchEsimBundlesDto } from "@/api/services/packages/esim/location-packages/types";
import { IBalanceOperation } from "@/hooks/balances/useBalances";
import { pagesService } from "./services/pages/pages.service";
import { IPages, IPagesData } from "./interfaces/pages/IPages";
import { aboutLocationService } from "./services/locations/aboutLocationService";
import {
  IAboutLocation,
  IAboutLocationResponse
} from "./interfaces/about-locations/aboutLocation";
import {
  ILocalizationsData,
  ILocalizationsResponse
} from "./interfaces/localizations/ILocalizations";
import { localizationsService } from "./services/localizations/localizations.service";
import {
  ICreateLocalizationDto,
  IUploadLocalizationImageDto
} from "./services/localizations/localizationsTypes";
import { localizationsLocation } from "@/hooks/locations/new/modules/location.update";
import { IMappedAboutLocalization } from "./services/about-products/aboutTypes";
import {
  IFetchDirectoryLocalizationData,
  IMappedDirectoryLocalization
} from "@/hooks/esim/directories/types/esimDirectoryLocalizations.types";
import { TEsimDirectoryTypes } from "@/hooks/esim/directories/types/esimDirectories.types";
import {
  IFetchFaqLocalizationData,
  IMappedFaqLocalization
} from "./services/faq/faqLocalizationsTypes";
import {
  AddStripeAccountDto,
  IStripeAccountsResponse
} from "./interfaces/stripe-accounts/IStripeAccounts";
import { stripeAccountsService } from "@/api/services/stripe-accounts/stripe-accounts.service";
import {
  IEsimPlansFaq,
  esimPlansFaqRequest
} from "./interfaces/esim-plans-faq/IEsimPlansFaq";
import { esimPlansFaqService } from "@/api/services/esim-plans-faq/esimPlansFaq.service";

export class Api implements IApi {
  http: httpType;

  constructor(http: httpType) {
    this.http = http;
  }

  request(
    url: string,
    method: httpMethodType,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<IApiResponse> {
    let _data: object = {};

    let updatedConfig = config;

    if (method !== "get" && method !== "delete" && data) {
      _data = { ...data, config };
      updatedConfig = {};
    }

    return this.http[method](url, _data, updatedConfig)
      .then(({ data: response }: AxiosResponse) => {
        const total: number | undefined = response?.total;
        const extraKeys = ["type"];
        // todo return to prev implementation.
        const result = Array.isArray(response)
          ? response
          : response.data
          ? response.data
          : response.profile
          ? { ...response.profile, ...response.balance }
          : response.results
          ? response.results
          : response.id;
        extraKeys.forEach(key => {
          if (key in response) {
            result[key] = response[key];
          }
        });

        return new ApiResponse(true, response.status?.code, result, total);
      })
      .catch(({ response }: AxiosError) => {
        if (!response) {
          return new ApiResponse(false, -1);
        }

        return new ApiResponse(false, response.data.error.code || -1);
      });
  }

  async createAxiosRequest(
    url?: string,
    method?: httpMethodType,
    data?: object
  ): Promise<IApiResponse> {
    return await axios
      .request({
        method,
        url,
        data,
        headers: {
          Authorization: `Bearer ${appStorage.get("token")}`
        }
      })
      .then(({ data: response }: AxiosResponse) => {
        const total: number | undefined = response?.total;
        const extraKeys = ["type"];

        // todo return to prev implementation.
        const result = Array.isArray(response)
          ? response
          : response?.data?.selected ||
            response.data ||
            response.profile ||
            response.results ||
            response.id ||
            response;

        extraKeys.forEach(key => {
          if (key in response) {
            result[key] = response[key];
          }
        });

        return new ApiResponse(true, response.status?.code, result, total);
      })
      .catch(({ response }: AxiosError) => {
        if (!response) {
          return new ApiResponse(false, -1);
        }

        return new ApiResponse(false, response.data.error.code || -1);
      });
  }

  uploadImage(
    url: string,
    method: httpMethodType,
    file?: File,
    paramName?: string,
    params?: Record<string, any>,
    onUploadProgress?: (evt: ProgressEvent) => void
  ): Promise<IApiResponse> {
    const formData = new FormData();
    if (paramName && file) {
      formData.append(paramName, file);
    }
    if (params) {
      for (const key in params) {
        formData.append(key, params[key]);
      }
    }

    const requestUrl = `${process.env.VUE_APP_BASE_API_URL + url}`;

    return axios({
      baseURL: `${requestUrl}`,
      headers: {
        "Content-Type":
          file !== undefined
            ? "multipart/form-data;"
            : "application/json; charset=utf-8",
        Authorization: `Bearer ${appStorage.get("token")}`
      },

      onUploadProgress(evt: ProgressEvent) {
        onUploadProgress?.(evt);
      },
      method,
      data: formData ? formData : {}
    })
      .then(({ data: response }: AxiosResponse) => {
        const total: number | undefined = response?.total;
        const extraKeys = ["type"];

        const result =
          response.data ||
          response.profile ||
          response.results ||
          response.id ||
          response;

        extraKeys.forEach(key => {
          if (key in response) {
            result[key] = response[key];
          }
        });

        return new ApiResponse(true, response.status?.code ?? 0, result, total);
      })
      .catch(({ response }: AxiosError) => {
        if (!response) {
          return new ApiResponse(false, -1);
        }

        return new ApiResponse(false, response.data.error.code || -1);
      });
  }

  public async fetchAccounts(
    query: accountsQuery,
    filter: accountsFilterSchema
  ): Promise<IAccountsResponse> {
    return await usersService.fetchAccountsService(
      this.request.bind(this),
      query,
      filter
    );
  }

  public async fetchAllAccounts(): Promise<IAccountsResponse> {
    return await usersService.fetchAllUsers(this.request.bind(this));
  }

  // Auth
  public async login(credentials: ILoginCredentials): Promise<ILoginResponse> {
    return await loginService(this.request.bind(this), credentials);
  }

  public async refreshToken(
    refreshToken: string
  ): Promise<IRefreshTokenResponse> {
    return await refreshTokenService(this.request.bind(this), refreshToken);
  }

  //payments
  public async fetchUserPayments(
    query: accountsQuery,
    filter: any
  ): Promise<IPayments> {
    return await paymentsService.fetchPayments(
      this.request.bind(this),
      query,
      filter
    );
  }

  public async fetchPaymentsById(id: number): Promise<IPayments> {
    return await paymentsService.fetchUserPayments(this.request.bind(this), id);
  }

  public async refundCreate(data: CreateRefundDto) {
    return await paymentsService.createRefund(
      this.createAxiosRequest.bind(this),
      data
    );
  }

  // categories
  public async fetchCategories(query: IBaseQuery): Promise<IFaqCategoriesData> {
    return await faqService.fetchCategories(this.request.bind(this), query);
  }

  public async addEditCategory(
    reqData: ICategories,
    method: httpMethodType,
    file: File,
    id: number | undefined
  ): Promise<IApiResponse> {
    return await faqService.actionWithCategory(
      this.uploadImage.bind(this),
      reqData,
      method,
      file,
      id
    );
  }

  public async removeCategory(id: number): Promise<IApiResponse> {
    return await faqService.removeCategory(this.request.bind(this), id);
  }

  // questions
  public async fetchQuestions(
    query: accountsQuery,
    id: number
  ): Promise<IFaqQuestionsData> {
    return await faqService.fetchQuestions(this.request.bind(this), query, id);
  }

  public async addQuestion(reqData: IQuestions): Promise<IApiResponse> {
    return await faqService.addQuestion(this.request.bind(this), reqData);
  }

  //esim plans faq
  public async fetchEsimPlansFaqList(
    query: accountsQuery
  ): Promise<esimPlansFaqRequest> {
    return await esimPlansFaqService.fetchEsimPlansFaqList(
      this.request.bind(this),
      query
    );
  }

  public async addEsimPlansFaqQuestion(
    reqData: IEsimPlansFaq
  ): Promise<IApiResponse> {
    return await esimPlansFaqService.addEsimPlansFaqQuestion(
      this.request.bind(this),
      reqData
    );
  }

  public async editEsimPlansFaqQuestion(
    reqData: IEsimPlansFaq,
    id: number
  ): Promise<IApiResponse> {
    return await esimPlansFaqService.editEsimPlansFaqQuestion(
      this.request.bind(this),
      reqData,
      id
    );
  }

  public async removeEsimPlansFaqQuestion(id: number): Promise<IApiResponse> {
    return await esimPlansFaqService.removeEsimPlansFaqQuestion(
      this.request.bind(this),
      id
    );
  }

  //faq localizations
  public async fetchFaqLocalizations(faqRecordId: number, type: string) {
    return await faqService.fetchFaqLocalizations(
      this.request.bind(this),
      faqRecordId,
      type
    );
  }

  public async editFaqLocalization(
    editData: IMappedFaqLocalization,
    type: string
  ) {
    return await faqService.editFaqLocalization(
      this.request.bind(this),
      editData,
      type
    );
  }

  // with text
  public async editQuestion(
    reqData: IQuestions,
    id: number
  ): Promise<IApiResponse> {
    return await faqService.editQuestion(this.request.bind(this), reqData, id);
  }

  public async removeQuestion(id: number): Promise<IApiResponse> {
    return await faqService.removeQuestion(this.request.bind(this), id);
  }

  // Users
  public async fetchUserData(userId: number): Promise<IUserDetailsResponse> {
    return await fetchUserDataService(this.request.bind(this), userId);
  }

  public async fetchUser(id: string): Promise<IAccountsResponse> {
    return await usersService.fetchUser(this.request.bind(this), id);
  }

  public async fetchLoginHistory(
    id: string,
    query: accountsQuery
  ): Promise<IAccountsResponse> {
    return await usersService.fetchLoginHistory(
      this.request.bind(this),
      id,
      query
    );
  }

  public async addUser(data: AddUserDto): Promise<IApiResponse> {
    return await usersService.addUser(this.request.bind(this), data);
  }

  public async editUser(data: EditUserDto): Promise<IApiResponse> {
    return await usersService.editUser(this.request.bind(this), data);
  }

  public async removeUser(id: number): Promise<IAccountsResponse> {
    return await usersService.removeUser(this.request.bind(this), id);
  }

  public async setActive(
    id: number,
    param: boolean
  ): Promise<IAccountsResponse> {
    return await usersService.setActive(this.request.bind(this), id, param);
  }

  public async setBlocked(
    id: number,
    param: boolean
  ): Promise<IAccountsResponse> {
    return await usersService.setBlocked(this.request.bind(this), id, param);
  }

  public async uploadUserAvatar(
    id: number,
    avatar: File
  ): Promise<IApiResponse> {
    return await usersService.uploadImage(
      this.uploadImage.bind(this),
      id,
      avatar
    );
  }

  // Templates
  public async fetchTemplates(
    query: templatesQuery
  ): Promise<ITemplatesDataResponse> {
    return await templatesService.fetchTemplatesData(
      this.request.bind(this),
      query
    );
  }

  public async fetchTemplatesTypes(): Promise<ITemplatesTypesDataResponse> {
    return await templatesService.fetchTemplatesTypeData(
      this.request.bind(this)
    );
  }

  public async addTemplate(
    data: templatesData
  ): Promise<ITemplatesDataResponse> {
    return await templatesService.addTemplate(this.request.bind(this), data);
  }

  public async editTemplateData(
    data: templatesData
  ): Promise<ITemplatesDataResponse> {
    return await templatesService.updateTemplate(this.request.bind(this), data);
  }

  public async deleteTemplate(id: number): Promise<ITemplatesDataResponse> {
    return await templatesService.deleteTemplate(this.request.bind(this), id);
  }

  // Suported Devices

  // Brands
  public async fetchBrands_(query: accountsQuery): Promise<IApiResponse> {
    return await brandsService.fetchBrandsService(
      this.request.bind(this),
      query
    );
  }

  public async addBrand(reqData: IBrands, file: File): Promise<IApiResponse> {
    return await brandsService.addBrandsService(
      this.request.bind(this),
      reqData
    );
  }

  public async editBrand(reqData: IBrands, id: number): Promise<IApiResponse> {
    return await brandsService.editBrandService(
      this.request.bind(this),
      reqData,
      id
    );
  }

  public async removeBrand(id: number): Promise<IApiResponse> {
    return await brandsService.removeBrandService(this.request.bind(this), id);
  }

  // devices
  public async fetchDevices(
    query: IBaseQuery,
    id: number
  ): Promise<IDevicesResponse> {
    return await suportedDevicesService.fetchDevicesList(
      this.request.bind(this),
      query,
      id
    );
  }

  public async addDevice(
    query: AddDeviceDto,
    file: File
  ): Promise<IApiResponse> {
    return await suportedDevicesService.addDevice(
      this.uploadImage.bind(this),
      query,
      file
    );
  }

  public async editDevice(
    query: AddDeviceDto,
    id: number,
    file: File
  ): Promise<IApiResponse> {
    if (file) {
      return await suportedDevicesService.editDevice(
        this.uploadImage.bind(this),
        query,
        id,
        file
      );
    } else {
      return await suportedDevicesService.editDeviceWithOutFile(
        this.request.bind(this),
        query,
        id
      );
    }
  }

  public async removeDevice(id: number): Promise<IApiResponse> {
    return await suportedDevicesService.removeDevice(
      this.request.bind(this),
      id
    );
  }

  public async fetchBrands(query: IBrandsQuery): Promise<IBrandsResponse> {
    return await suportedDevicesService.fetchBrandsList(
      this.request.bind(this),
      query
    );
  }

  // About Products
  public async fetchAboutsList(value: string): Promise<IAboutsResponse> {
    return await aboutProductsService.fetchAboutsList(
      this.request.bind(this),
      value
    );
  }

  public async fetchAboutLocalizations(
    id: number,
    type: string
  ): Promise<IAboutsResponse> {
    return await aboutProductsService.fetchAboutLocalizations(
      this.request.bind(this),
      id,
      type
    );
  }

  // About Products
  public async editAbouts(
    value: string,
    data: EditDataAbouts
  ): Promise<IApiResponse> {
    return await aboutProductsService.editAbouts(
      this.request.bind(this),
      value,
      data
    );
  }

  public async editAboutLocalization(
    data: IMappedAboutLocalization,
    type: string
  ): Promise<IApiResponse> {
    return await aboutProductsService.editAboutLocalization(
      this.request.bind(this),
      data,
      type
    );
  }

  // Location packages

  public async fetchLocationPackages(
    options: fetchLocationPackagesDto
  ): Promise<IFetchLocationPackagesResponse> {
    return await locationPackageService.fetchLocationPackages(
      this.request.bind(this),
      options
    );
  }

  public async fetchLocationPackagesRates(
    options: fetchLocationPackagesRatesDto
  ): Promise<IFetchLocationPackagesRatesResponse> {
    return await locationPackageService.fetchLocationPackagesRates(
      this.request.bind(this),
      options
    );
  }

  public async updateLocationPackages(data: IUpdateLocationPackageDto) {
    return await locationPackageService.updateLocationPackages(
      this.request.bind(this),
      data
    );
  }

  public async removeLocationPackage(
    options: IRemoveLocationPackageDto
  ): Promise<IApiResponse> {
    return await locationPackageService.removeLocationPackage(
      this.request.bind(this),
      options
    );
  }

  // DIRECTORY

  public async fetchBundlesByProviderId(data: fetchEsimBundlesDto) {
    return await esimDirectoryService.fetchBundlesByProviderId(
      this.request.bind(this),
      data
    );
  }
  public async fetchBundles(pagination?: { page: number; perPage: number }) {
    return await esimDirectoryService.fetchBundles(
      this.request.bind(this),
      pagination
    );
  }
  public async fetchAllBundles() {
    return await esimDirectoryService.fetchAllBundles(this.request.bind(this));
  }

  public async synchronizeResellerBundles() {
    return await esimDirectoryService.synchronizeResellerBundles(
      this.request.bind(this)
    );
  }

  public async editBundle(id: string, name: string) {
    return await esimDirectoryService.editBundle(
      this.request.bind(this),
      id,
      name
    );
  }

  public async deleteBundle(id: string) {
    return await esimDirectoryService.deleteBundle(this.request.bind(this), id);
  }

  public async fetchDataTypes() {
    return await esimDirectoryService.fetchDataTypes(this.request.bind(this));
  }

  public async fetchPurchaseTypes() {
    return await esimDirectoryService.fetchPurchaseTypes(
      this.request.bind(this)
    );
  }

  public async fetchValidityTypes() {
    return await esimDirectoryService.fetchValidityTypes(
      this.request.bind(this)
    );
  }

  public async fetchNetworkGenerations(): Promise<
    IFetchNetworkGeolocationResponse
  > {
    return await esimDirectoryService.fetchNetworkGenerations(
      this.request.bind(this)
    );
  }

  public async fetchEsimZones(): Promise<IFetchEsimZonesResponse> {
    return await esimDirectoryService.fetchEsimZones(this.request.bind(this));
  }

  public async fetchDirectoryLocalizations(
    directoryRecordId: number,
    type: TEsimDirectoryTypes
  ): Promise<IFetchEsimDirectoryLocalizationsResponse> {
    return await esimDirectoryService.fetchDirectoryLocalizations(
      this.request.bind(this),
      directoryRecordId,
      type
    );
  }

  public async editDirectoryLocalization(
    editData: IMappedDirectoryLocalization,
    type: TEsimDirectoryTypes
  ) {
    return await esimDirectoryService.editDirectoryLocalization(
      this.request.bind(this),
      editData,
      type
    );
  }

  // COUNTRIES

  // public async fetchCountries(
  //   params: IFetchCountriesDTO
  // ): Promise<ICountriesResponse> {
  //   return await countriesService.fetchCountries(
  //     this.request.bind(this),
  //     params
  //   );
  // }

  // CARRIERS

  public async fetchCarriers(
    countryId: number
  ): Promise<IFetchCarriersResponse> {
    return await carriersService.fetchCarriers(
      this.request.bind(this),
      countryId
    );
  }

  public async updateCarriers(
    data: IUpdateCarrierDto,
    isCreate: boolean
  ): Promise<IApiResponse> {
    return await carriersService.updateCarriers(
      this.request.bind(this),
      this.uploadImage.bind(this),
      data,
      isCreate
    );
  }

  public async deleteCarriers(id: number) {
    return await carriersService.deleteCarriers(this.request.bind(this), id);
  }

  public async fetchCarriersCountries(
    params: IFetchCountriesDTO
  ): Promise<IFetchCarriersCountriesResponse> {
    return await carriersCountriesService.fetchCountries(
      this.request.bind(this),
      params
    );
  }

  // LOCATIONS

  public async fetchLocationsList<T extends TLocationKeys>(
    data: IFetchLocationsDto,
    locationType: T
  ): Promise<IFetchLocationsResponse<T>> {
    return await locationsService.fetchLocationsList(
      this.request.bind(this),
      data,
      locationType
    );
  }

  public async fetchLocationLocalization(
    localizationId: number,
    locationType: TPackageLocationSingle
  ): Promise<IFetchLocationLocalizationResponse> {
    return await locationsService.fetchLocationLocalizations(
      this.request.bind(this),
      localizationId,
      locationType
    );
  }

  public async fetchLocationFaqList(
    localionId: number,
    locationType: TPackageLocationSingle
  ): Promise<IFetchLocationFaqListResponse> {
    return await locationsService.fetchLocationFaqList(
      this.request.bind(this),
      localionId,
      locationType
    );
  }

  public async deleteLocationFaq(
    faqId: number,
    locationType: TPackageLocationSingle
  ) {
    return await locationsService.deleteLocationFaq(
      this.request.bind(this),
      faqId,
      locationType
    );
  }

  public async updateLocationFaq(
    data: ILocationFaqList,
    locationType: TPackageLocationSingle
  ): Promise<IApiResponse> {
    return await locationsService.updateLocationFaq(
      this.request.bind(this),
      data,
      locationType
    );
  }

  public async deleteLocation<T extends TPackageLocation>(
    location: IDeleteLocationDto<T>
  ) {
    return await locationsService.deleteLocation(
      this.request.bind(this),
      location
    );
  }

  public async fetchSelectedLocations<
    List extends selectedLocationListType,
    Location extends selectedLocationType
  >(data: IFetchSelectedLocationsDto<List, Location>) {
    return await locationsService.fetchSelectedLocations(
      this.request.bind(this),
      data
    );
  }

  public async uploadLocationImage(payload: IUploadLocationImageDto) {
    return await locationsService.uploadLocationImage(
      this.uploadImage.bind(this),
      payload
    );
  }

  public async createLocation(
    payload: ICreateLocationDto,
    locationType: TPackageLocationSingle
  ) {
    return await locationsService.updateLocation(
      this.request.bind(this),
      payload,
      locationType
    );
  }

  public async editLocation(
    payload: ICreateLocationDto,
    locationType: TPackageLocationSingle,
    id?: number
  ) {
    return await locationsService.updateLocation(
      this.request.bind(this),
      payload,
      locationType,
      id,
      "patch"
    );
  }

  public async saveLocalization(
    payload: localizationsLocation[],
    locationType: TPackageLocationSingle
  ) {
    return await locationsService.saveLocalization(
      this.request.bind(this),
      payload,
      locationType,
      "post"
    );
  }

  // esim profiles

  public async fetchEsimServers(id: number, year: string) {
    return await profilesService.fetchEsimServers(
      this.request.bind(this),
      id,
      year
    );
  }

  public async fetchEsimTypes() {
    return await profilesService.fetchEsimTypes(this.request.bind(this));
  }
  public async fetchEsimTypesForPlans() {
    return await profilesService.fetchEsimTypesForPlans(
      this.request.bind(this)
    );
  }

  public async fetchPlans() {
    return await esimDirectoryService.fetchPlans(this.request.bind(this));
  }

  public async fetchEsimProfiles(data: fetchEsimProfilesDto) {
    return await profilesService.fetchEsimProfiles(
      this.request.bind(this),
      data
    );
  }

  public async fetchEsimProviderById(data: fetchEsimProviderDto) {
    return await profilesService.fetchEsimProviderById(
      this.request.bind(this),
      data
    );
  }

  public async synchronizeResellerProfiles() {
    return await profilesService.synchronizeResellerProfiles(
      this.request.bind(this)
    );
  }

  public async getEsimQr(type: string, listId: number | string) {
    return await profilesService.getEsimQr(
      this.createAxiosRequest.bind(this),
      type,
      listId
    );
  }

  public async importProfile(
    table: File,
    serverId: number,
    onProgress?: (progress: number) => void
  ) {
    return await profilesService.importProfile(
      this.uploadImage.bind(this),
      table,
      serverId,
      onProgress
    );
  }

  public async importBundle(
    table: File,
    onProgress?: (progress: number) => void
  ) {
    return await profilesService.importBundle(
      this.uploadImage.bind(this),
      table,
      onProgress
    );
  }

  public async getEsimPlans(
    query: IBaseQuery,
    filter: string,
    value: string
  ): Promise<IApiResponse> {
    return await plansService.fetchEsimPlans(
      this.request.bind(this),
      query,
      filter,
      value
    );
  }

  public async updateEsimPlanStatus(
    id: number,
    status: boolean,
    value: string
  ): Promise<IApiResponse> {
    return await plansService.updatePlanStatus(
      this.request.bind(this),
      id,
      status,
      value
    );
  }

  public async updateEsimPlanOperatorStatus(
    id: number,
    status: boolean,
    value: string
  ): Promise<IApiResponse> {
    return await plansService.updatePlanOperatorStatus(
      this.request.bind(this),
      id,
      status,
      value
    );
  }
  public async changePlanEsimZone(
    id: number,
    zone: IZone
  ): Promise<IApiResponse> {
    return await plansService.changePlanEsimZone(
      this.request.bind(this),
      id,
      zone
    );
  }
  public async changePlanEsimServer(
    data: editEsimPlanEsimServerDto,
    value: string
  ): Promise<IApiResponse> {
    return await plansService.changePlanEsimServer(
      this.request.bind(this),
      data,
      value
    );
  }
  // Notes
  public async fetchNotesList(query: notesQuery, id: number) {
    return await notesService.fetchNotesList(
      this.request.bind(this),
      query,
      id
    );
  }
  public async createNote(id: number, data: CreateNoteDto) {
    return await notesService.addNotes(this.request.bind(this), id, data);
  }

  public async changeStatusNotes(
    id: number,
    noteId: number,
    data: ChangeStatusNoteDto
  ) {
    return await notesService.changeStatusNotes(
      this.request.bind(this),
      id,
      noteId,
      data
    );
  }

  public async removeNote(id: number, noteId: number) {
    return await notesService.deleteNote(this.request.bind(this), id, noteId);
  }

  // User Esim Profile
  public async getUserEsimProfileList(userId: number): Promise<IApiResponse> {
    return await esimProfilesService.fetchEsimProfilesList(
      this.request.bind(this),
      userId
    );
  }

  // Promo Codes\
  public async getPromoCodes(query: IBaseQuery) {
    return await promoCodesSevice.fetchPromoCodes(
      this.request.bind(this),
      query
    );
  }

  public async createPromoCode(data: PromoCodesDto) {
    return await promoCodesSevice.createPromo(this.request.bind(this), data);
  }

  public async updatePromoCode(id: number, data: PromoCodesDto) {
    return await promoCodesSevice.updatePromo(
      this.request.bind(this),
      id,
      data
    );
  }

  public async deletePromoCode(id: number) {
    return await promoCodesSevice.deletePromo(this.request.bind(this), id);
  }

  // esim directories
  public async fetchEsimDirectoryData(link: string) {
    return await esimDirectoryService.fetchEsimDirectoryData(
      this.request.bind(this),
      link
    );
  }

  public async uploadEsimDirectoryImages(type: string, file: File) {
    return await esimDirectoryService.uploadImage(
      this.uploadImage.bind(this),
      type,
      file
    );
  }

  public async addEsimDirectory(data: IEsimDirectoryDefault, link: string) {
    return await esimDirectoryService.add(this.request.bind(this), data, link);
  }

  public async updateEsimDirectory(
    data: IEsimDirectoryDefault,
    link: string,
    id: number
  ) {
    return await esimDirectoryService.update(
      this.request.bind(this),
      data,
      link,
      id
    );
  }

  public async deleteEsimDirectory(link: string, id: number) {
    return await esimDirectoryService.delete(this.request.bind(this), link, id);
  }

  public async fetchEsimRates(): Promise<IRate> {
    const { data } = await esimDirectoryService.fetchEsimRates(
      this.request.bind(this)
    );
    return data;
  }

  public async updateEsimRates(reqData: IRate): Promise<IApiResponse> {
    return await esimDirectoryService.updateEsimRates(
      this.request.bind(this),
      reqData
    );
  }

  public async updateEsimPlanDiscount(
    data: UpdatePlanDiscountDto
  ): Promise<IApiResponse> {
    return await plansService.changeDiscount(this.request.bind(this), data);
  }

  // Logs
  public async fetchLogs(query: GetQueryParamsLoggerDto): Promise<any> {
    return await logsService.fetchLogs(this.request.bind(this), query);
  }

  public async removeLogs(
    tab: string,
    activeFrom: string,
    activeTo: string
  ): Promise<IApiResponse> {
    return await logsService.removeLogs(
      this.request.bind(this),
      tab,
      activeFrom,
      activeTo
    );
  }

  public async removeAllLogs(tab: string): Promise<IApiResponse> {
    return await logsService.removeAllLogs(this.request.bind(this), tab);
  }

  // balances
  public async fetchBalanceHistory(
    userId: number,
    query: IBaseQuery
  ): Promise<balancesHistoryData> {
    return await balancesService.fetchBalanceHistory(
      this.request.bind(this),
      userId,
      query
    );
  }

  public async fetchUserBalance(userId: number): Promise<userBalanceData> {
    return await balancesService.fetchUserBalance(
      this.request.bind(this),
      userId
    );
  }

  public async addBalanceOperation(
    userId: number,
    data: IBalanceOperation
  ): Promise<IApiResponse> {
    return await balancesService.addBalanceOperation(
      this.request.bind(this),
      userId,
      data
    );
  }

  //pages
  public async fetchPages(query: IBaseQuery): Promise<IPagesData> {
    return await pagesService.fetchPages(this.request.bind(this), query);
  }

  public async addEditPages(
    data: IPages,
    method: httpMethodType,
    id: number | undefined
  ): Promise<IApiResponse> {
    return await pagesService.actionWithPage(
      this.uploadImage.bind(this),
      data,
      method,
      id
    );
  }

  public async removePages(id: number): Promise<IApiResponse> {
    return await pagesService.removePage(this.request.bind(this), id);
  }

  public async fetchPagesFaq(
    query: IBaseQuery,
    pageId: number | null
  ): Promise<IFaqQuestionsData> {
    return await pagesService.fetchPagesFaq(
      this.request.bind(this),
      query,
      pageId
    );
  }

  public async addEditPageFaq(
    data: IPages,
    method: httpMethodType,
    id: number | undefined
  ): Promise<IApiResponse> {
    return await pagesService.actionWithPageFaq(
      this.request.bind(this),
      data,
      method,
      id
    );
  }

  //About location

  public async fetchAboutLocation(): Promise<IAboutLocationResponse> {
    return await aboutLocationService.fetchAboutLocation(
      this.request.bind(this)
    );
  }

  public async addEditAboutLocation(
    data: IAboutLocation,
    method: httpMethodType,
    id: number | undefined
  ): Promise<IApiResponse> {
    return await aboutLocationService.actionAboutLocation(
      this.request.bind(this),
      data,
      method,
      id
    );
  }

  //Localizations

  public async uploadLocalizationImage(payload: IUploadLocalizationImageDto) {
    return await localizationsService.uploadLocalizationImage(
      this.uploadImage.bind(this),
      payload
    );
  }

  public async editLocalization(payload: ICreateLocalizationDto, id?: number) {
    return await localizationsService.updateLocalization(
      this.request.bind(this),
      payload,
      id,
      "patch"
    );
  }

  public async createLocalization(payload: ICreateLocalizationDto) {
    return await localizationsService.updateLocalization(
      this.request.bind(this),
      payload
    );
  }

  public async removeLocalization(id: number) {
    return await localizationsService.removeLocalization(
      this.request.bind(this),
      id
    );
  }

  public async fetchLocalizations(
    query: IBaseQuery
  ): Promise<ILocalizationsData> {
    return await localizationsService.fetchLocalizations(
      this.request.bind(this),
      query
    );
  }

  // Stripe Accounts

  public async fetchStripeAccountsList(
    query: IBaseQuery
  ): Promise<IStripeAccountsResponse> {
    return await stripeAccountsService.fetchStripeAccountsList(
      this.request.bind(this),
      query
    );
  }

  public async addStripeAccount(
    body: AddStripeAccountDto
  ): Promise<IApiResponse> {
    return await stripeAccountsService.addStripeAccount(
      this.request.bind(this),
      body
    );
  }

  public async editStripeAccount(id: number, body: AddStripeAccountDto) {
    return await stripeAccountsService.editStripeAccount(
      this.request.bind(this),
      body,
      id
    );
  }

  public async removeStripeAccount(id: number) {
    return await stripeAccountsService.removeStripeAccount(
      this.request.bind(this),
      id
    );
  }

  public async restartPaymentServer() {
    return await stripeAccountsService.restartPaymentServer(
      this.request.bind(this)
    );
  }

  public async getUsedStripeAccounts() {
    return await stripeAccountsService.getUsedStripeAccounts(
      this.createAxiosRequest.bind(this)
    );
  }
}

export const api = new Api($axios);
