<template>
  <div
    class="user-info"
    :class="{
      'name-only': !description
    }"
    v-bind="$attrs"
  >
    <div class="d-flex align-items-center">
      <div class="user-avatar">
        <img v-if="avatar" :src="avatar" :alt="name" />
        <img
          v-else
          :src="require('@/assets/img/avatar-placeholder.svg')"
          alt="No photo"
        />
      </div>

      <div>
        <h4 class="user-name">{{ name }}</h4>
        <p v-if="description" class="user-description">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  props: {
    name: {
      type: [String, null],
      required: true
    },

    description: {
      type: String,
      required: false
    },

    avatar: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
.user-info {
  font-size: 1rem;
  min-width: 12.14em;

  &.w-100 {
    width: 100%;
  }
}

.user-avatar {
  font-size: 3.57em;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;

  position: relative;
  overflow: hidden;
  margin-right: $grid-gutter-width * 0.7;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    height: 102%;
    object-fit: cover;
    border-radius: inherit;
  }
}

.user-name {
  font-size: 1.07em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-description {
  font-size: 0.928em;
  color: $grey-100;
}

.user-name,
.user-description {
  margin: 0;
}
</style>
