import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import store from "@/store/index";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, _, next) => {
  store.commit("setActiveRoute", to);

  if (to.fullPath === "/") {
    next("/accounts");
  } else {
    next();
  }
});

export default router;
