export function queryObjectToUrlString(query: { [key: string]: any }): string {
  return Object.entries(query)
    .reduce((acc, param, idx: number): string => {
      const key = param[0] as string;
      const value = param[1] as string;
      if (idx !== 0) {
        acc += "&";
      }

      acc += `${key}=${value}`;
      return acc;
    }, "")
    .trim();
}
