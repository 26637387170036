import {
  IAuthState,
  token,
  userId,
  IAccessCredentials
} from "@/store/interfaces/auth/common";
import { LoginAction } from "@/store/modules/auth/LoginAction";

import { Commit, Dispatch } from "vuex";
import { appStorage } from "@/storage/storage";
import { refreshTokenAction } from "@/store/modules/auth/RefreshTokenAction";

const initialToken: token = appStorage.get("token") || null;
const initialRefreshToken: token = appStorage.get("refreshToken") || null;

const initialState: IAuthState = {
  token: initialToken,
  refreshToken: initialToken,
  authorized: !!(initialToken && initialRefreshToken),
  userId: null
};

export const auth = {
  state(): IAuthState {
    return initialState;
  },

  getters: {
    token: ({ token }: IAuthState): token => token,
    refreshToken: ({ refreshToken }: IAuthState): token => refreshToken,
    userId: ({ userId }: IAuthState): userId => userId,
    authorized: ({ authorized }: IAuthState): boolean => authorized
  },

  mutations: {
    setCredentials(
      state: IAuthState,
      { token, refreshToken }: IAccessCredentials
    ): void {
      state.token = token;
      state.refreshToken = refreshToken;
      state.authorized = !!(token && refreshToken);

      appStorage.set("token", token || "");
      appStorage.set("refreshToken", refreshToken || "");
    }
  },

  actions: {
    login: LoginAction,
    refreshToken: refreshTokenAction,
    logout({ commit }: { commit: Commit }) {
      commit("setCredentials", { token: null, refreshToken: null });
    },
    async onAuthExpired({ dispatch }: { dispatch: Dispatch }): Promise<void> {
      await dispatch("logout");
    }
  }
};
