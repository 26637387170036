export const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => {
      return import(/* webpackChunkName: "login" */ "../views/Login.vue");
    },
    showInSidebar: false,
    id: "login",
    parentId: null
  },

  {
    path: "/test",
    name: "test",
    component: () => {
      return import(/* webpackChunkName: "login" */ "../views/Test.vue");
    }
  },
  {
    path: "/",
    name: "Home",
    component: () => {
      return import(/* webpackChunkName: "Accounts" */ "../views/Accounts");
    },
    showInSidebar: false,
    id: "home",
    parentId: null
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    showInSidebar: false,
    component: null,
    id: "dashboard",
    parentId: null
  },

  {
    path: "/accounts",
    name: "Accounts",
    icon: "accounts",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "Accounts" */ "../views/Accounts");
    },
    id: "accounts",
    parentId: null,
    count: 0
  },

  {
    path: "/user",
    name: "User",
    icon: null,
    id: "user",
    parentId: "accounts",
    component: () => {
      return import(/* webpackChunkName: "User" */ "../views/User.vue");
    }
  },
  // {
  //   path: "/hold-users-list",
  //   name: "Hold users list",
  //   icon: "hold-users",
  //   showInSidebar: true,
  //   component: () => {
  //     return import(
  //       /* webpackChunkName: "HoldUsersList" */ "../views/HoldUsersList.vue"
  //     );
  //   },
  //   id: "hold-users-list",
  //   parentId: null,
  //   count: 0
  // },

  {
    path: "/templates",
    name: "Templates",
    icon: "templates",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "Templates" */ "../views/Templates.vue"
      );
    },
    id: "templates",
    parentId: null
  },
  {
    path: "/packages",
    name: "Packages",
    icon: "packages",
    showInSidebar: true,
    component: null,
    id: "packages",
    parentId: null
  },
  {
    path: "/virtual-numbers",
    name: "Virtual numbers",
    icon: null,
    component: null,
    id: "virtual-numbers",
    parentId: "packages",
    showInSidebar: true
  },
  {
    path: "/virtual-numbers/packages",
    name: "Packages",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "voip-packages" */ "../views/packages/virtual-numbers/VirtualNumbersPackages.vue"
      );
    },
    id: "Packages",
    parentId: "virtual-numbers"
  },
  {
    path: "/virtual-numbers/rates",
    name: "Virtual numbers Rates",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "voip-rates" */ "../views/packages/virtual-numbers/Rates.vue"
      );
    },
    id: "virtual-numbers/rates",
    parentId: "virtual-numbers"
  },
  {
    path: "/virtual-numbers/credit-packs",
    name: "Credit Packs",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "credit-packs" */ "../views/packages/virtual-numbers/CreditPacks.vue"
      );
    },
    id: "virtual-numbers/credit-packs",
    parentId: "virtual-numbers"
  },
  {
    path: "/packages/translation",
    name: "Translation",
    icon: null,
    component: () => {
      return import(
        /* webpackChunkName: "translation" */ "../views/packages/Translation.vue"
      );
    },
    id: "translation",
    showInSidebar: true,
    parentId: "packages"
  },
  {
    path: "/eSIM",
    name: "eSIM",
    icon: null,
    component: null,
    id: "eSIM",
    parentId: "packages",
    showInSidebar: true
  },

  {
    path: "/esim/packages/carrirsCountries",
    name: "Country Packages",
    icon: null,
    showInSidebar: true,
    parentId: "eSIM",
    id: "/esim/packages/carrirsCountries",

    component: () =>
      import(
        /* webpackChunkName: "subregionPackages.vue" */ "../views/packages/eSIM/LocationPackages.vue"
      )
  },

  {
    path: "/esim/packages/regions",
    name: "Region Packages",
    icon: null,
    showInSidebar: true,
    parentId: "eSIM",
    id: "/esim/packages/regions",

    component: () =>
      import(
        /* webpackChunkName: "subregionPackages.vue" */ "../views/packages/eSIM/LocationPackages.vue"
      )
  },

  {
    path: "/esim/packages/subregions",
    name: "Subregion Packages",
    parentId: "eSIM",
    id: "/esim/packages/subregions",
    icon: null,
    showInSidebar: true,
    component: () =>
      import(
        /* webpackChunkName: "subregionPackages.vue" */ "../views/packages/eSIM/LocationPackages.vue"
      )
  },
  {
    path: "/eSIM/carriers",
    name: "Carriers",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-Carriers" */ "../views/packages/eSIM/Carriers"
      );
    },
    id: "/eSIM/сarriers",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/plans",
    name: "Plans",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-plans" */ "../views/packages/eSIM/eSIMPlans"
      );
    },
    id: "/eSIM/plans",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/directory",
    name: "Directory",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-directory" */ "../views/packages/eSIM/EsimDirectory"
      );
    },
    id: "/eSIM/directory",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/profiles",
    name: "Profiles",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-profiles" */ "../views/packages/eSIM/ESIMProfiles"
      );
    },
    id: "/eSIM/profiles",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/bundles",
    name: "Bundles",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-bundles" */ "../views/packages/eSIM/ESIMBundles"
      );
    },
    id: "/eSIM/profiles",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/rates",
    name: "Rates",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-rates" */ "../components/Packages/Rates/EsimRatesModal"
      );
    },
    id: "/eSIM/profiles",
    parentId: "eSIM"
  },
  {
    path: "/promo-codes",
    name: "Promo Codes",
    icon: "promo",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "promo-codes" */ "../views/PromoCodes.vue"
      );
    },
    parentId: null,
    id: "promo-codes"
  },
  {
    path: "/operators",
    name: "Operators",
    icon: "operators",
    component: () => {
      return import(/* webpackChunkName: "about" */ "../views/Operators.vue");
    },
    parentId: null,
    id: "operators",
    showInSidebar: true
  },
  {
    path: "/operator",
    name: "Operator",
    icon: null,
    component: () => {
      return import(/* webpackChunkName: "operator" */ "../views/Operator.vue");
    },
    parentId: "operators",
    id: "operator",
    showInSidebar: false
  },
  {
    path: "/admins",
    name: "Admins",
    icon: "admins",
    component: () => {
      return import(/* webpackChunkName: "admins" */ "../views/Admins.vue");
    },
    parentId: null,
    id: "admins",
    showInSidebar: true
  },
  {
    path: "/admin",
    name: "Admin",
    icon: null,
    component: () => {
      return import(/* webpackChunkName: "admin" */ "../views/Admin.vue");
    },
    parentId: "admins",
    id: "admin",
    showInSidebar: false
  },

  {
    path: "/payments",
    name: "Payments",
    icon: "payments",
    component: () => {
      return import(/* webpackChunkName: "payments" */ "../views/Payments.vue");
    },
    parentId: null,
    id: "payments",
    showInSidebar: true
  },
  {
    path: "/notification-center",
    name: "Notification center",
    icon: "notification-center",
    component: null,
    parentId: null,
    id: "notification-center",
    showInSidebar: true
  },
  {
    path: "/locations",
    name: "Locations",
    icon: "locations",
    component: () => {
      return import(
        /* webpackChunkName: "locations" */ "../views/Locations.vue"
      );
    },
    parentId: null,
    id: "locations",
    showInSidebar: true
  },
  {
    path: "/localizations",
    name: "Localizations",
    icon: "language-translation",
    component: () => {
      return import(
        /* webpackChunkName: "locations" */ "../views/Localizations.vue"
      );
    },
    parentId: null,
    id: "localizations",
    showInSidebar: true,
  },
  {
    path: "/website-pages",
    name: "Pages",
    icon: "settings",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "Accounts" */ "../views/Pages");
    },
    id: "pages",
    parentId: null
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "faq",
    component: null,
    parentId: null,
    id: "faq",
    showInSidebar: true
  },
  {
    path: "/faq/categories",
    name: "Categories",
    icon: "categories",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "categories" */ "../views/faq/Categories"
      );
    },
    parentId: "faq",
    id: "/faq/categories"
  },
  {
    path: "/faq/questions",
    name: "Questions",
    icon: "questions",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "questions" */ "../views/faq/Questions"
      );
    },
    parentId: "faq",
    id: "/faq/questions"
  },
  {
    path: "/faq/pages",
    name: "Pages FAQ",
    icon: "questions",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "questions" */ "../views/faq/Pages");
    },
    parentId: "faq",
    id: "/faq/pages"
  },
  {
    path: "/faq/esim-plans",
    name: "Esim Plans FAQ",
    icon: "questions",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "questions" */ "../views/faq/EsimPlans");
    },
    parentId: "faq",
    id: "/faq/esim-plans"
  },
  {
    path: "/about",
    name: "About product",
    icon: "about",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "about" */ "../views/About");
    },
    parentId: null,
    id: "about"
  },
  {
    path: "/supported-devices",
    name: "Supported devices",
    icon: "devices",
    component: null,
    id: "supported-devices",
    parentId: null,
    showInSidebar: true
  },
  {
    path: "/supported-devices/brands",
    name: "Brands",
    icon: "brands",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "brands" */ "../views/supported-devices/Brands"
      );
    },
    parentId: "supported-devices",
    id: "/supported-devices/brands"
  },
  {
    path: "/supported-devices/devices",
    name: "Devices",
    icon: "devices",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "devices" */ "../views/supported-devices/Devices"
      );
    },
    parentId: "supported-devices",
    id: "/supported-devices/devices"
  },
  {
    path: "/stripe-accounts",
    name: "StripeAccounts",
    icon: "credits",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "devices" */ "../views/StripeAccounts"
      );
    },
    parentId: null,
    id: "/stripeAccounts"
  },
  {
    path: "/logs",
    name: "Logs",
    icon: "clock",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "devices" */ "../views/Logs");
    },
    parentId: null,
    id: "/logs"
  }
  // {
  //   path: "/about",
  //   name: "AboutProject",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutProject.vue")
  // }
];
