<template>
  <div
    ref="dropdown"
    :class="`dropdown ${shown ? 'shown' : 'hidden'} dropdown-${direction}`"
    v-bind="$attrs"
  >
    <div class="dropdown-heading" @click="() => toggle(null)">
      <slot name="dropdown-title" />

      <SvgIcon v-if="!$slots.arrow" icon="chevron" />

      <slot class="arrow" name="arrow" />
    </div>

    <div ref="dropdownBody" class="dropdown-body">
      <slot name="dropdown-content" />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import SvgIcon from "@/components/ui/SvgIcon";
import { validator } from "@/components/ui/Dropdown/dropdown.helpers";

export default {
  name: "Dropdown",
  components: { SvgIcon },
  props: {
    height: { type: Number, default: 0 },
    openedOnMount: { type: Boolean, default: false },
    varsHidden: { type: Object, default: () => ({}) },
    varsShown: { type: Object, default: () => ({}) },
    parentOpened: { type: Boolean, default: false },
    duration: { type: Number, default: 0.35 },
    onOpen: { type: Function, default: () => () => {} },
    onClose: { type: Function, default: () => () => {} },
    direction: {
      type: String,
      default: "down",
      validator: dir => validator(dir)
    }
  },
  data() {
    return {
      shown: null,
      ease: "expo.easeIn",
      easeFrom: "expo.easeIn"
    };
  },

  computed: {
    $dropdown() {
      return this.$refs.dropdown;
    },
    $dropdownContent() {
      return this.$refs.dropdownBody;
    }
  },

  watch: {
    parentOpened: function(status) {
      this.shown = status;
    },

    shown: function(status) {
      if (this.$dropdownContent) {
        const vars = status
          ? {
              y: 0,
              height: "auto",
              autoAlpha: 1,
              ease: this.ease,
              duration: this.duration
            }
          : {
              y: -10,
              height: 0,
              autoAlpha: 0,
              ease: this.ease,
              duration: this.duration
            };

        if (this.varsShown && this.varsHidden) {
          gsap.to(this.$dropdown, {
            ...(status ? this.varsShown : this.varsHidden),
            duration: this.duration,
            ease: this.ease
          });
        }

        gsap.to(this.$dropdownContent, {
          ...vars,
          duration: this.duration,
          ease: this.ease
        });
      }

      status ? this.onOpen() : this.onClose();
    }
  },

  mounted() {
    if (!this.openedOnMount) {
      gsap.set(this.$dropdown, {
        ...(this.varsHidden ?? {})
      });

      gsap.set(this.$dropdownContent, {
        height: 0
      });
    }
  },

  methods: {
    toggle(status) {
      this.shown = status ?? !this.shown;
    }
  }
};
</script>

<style scoped lang="scss">
.dropdown {
  display: flex;
  flex-direction: column;
  &-up {
    flex-direction: column-reverse;
  }
}

.dropdown-heading {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  .icon {
    transition: transform 0.25s;
    transform: rotate(-90deg);
    font-size: 10px;
  }
}

.dropdown.shown {
  & > .dropdown-heading .icon {
    transform: rotate(0);
  }
}

.dropdown-body {
  position: relative;
  overflow: hidden;
}
</style>
