<template>
  <span
    class="indicator"
    :style="{
      width: width,
      minWidth: width,
      height: width
    }"
  >
    <span ref="indicatorContent" class="indicator-content">
      {{ count }}
    </span>
  </span>
</template>

<script>
export default {
  name: "Indicator",
  props: {
    count: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      width: "auto"
    };
  },

  mounted() {
    const $contentNode = this.$refs.indicatorContent;
    if ($contentNode) {
      const baseWidth = parseInt(
        window.getComputedStyle($contentNode, null).width
      );
      this.width = `${baseWidth}px`;
    }
  }
};
</script>

<style scoped lang="scss">
.indicator {
  font-size: 8px;
  font-weight: 700;
  width: max-content;

  color: $color-contrast-light;
  background-color: $color-danger;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: max-content;

  &-content {
    line-height: 1.57;
    padding: 0 4px;
    margin: 0;
  }
}
</style>
