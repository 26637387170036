<template>
  <FormControlWithIcon
    :id="id"
    icon="search"
    :class="fieldType"
    placeholder="search..."
    @change="changeHandler"
  />
</template>

<script>
import FormControlWithIcon from "@/components/Form/FormControlWithIcon";

export default {
  name: "SearchControl",
  components: { FormControlWithIcon },
  props: {
    id: { type: String, default: " " },
    name: {
      type: String,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    },

    timeout: {
      type: Number,
      default: 250
    },
    fieldType: { type: String, default: "" }
  },
  emits: { search: null },

  data() {
    return {
      searchTimeout: null
    };
  },

  methods: {
    changeHandler({ target }) {
      clearTimeout(this.searchTimeout);
      const value = target.value || "";
      this.searchTimeout = setTimeout(() => {
        this.onSearch(value);
        this.$emit("search", value);
      }, this.timeout);
    }
  }
};
</script>

<style lang="scss">
.inline-field.form-control-wrapper {
  border-radius: $border-radius;
  height: 35px;
  background-color: $grey-400;
  padding: 0.6em 1em;
  .icon {
    color: $grey-200;
  }

  input::placeholder {
    color: $grey-200;
  }
}
</style>
