import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse,
  IBaseQuery,
  IGApiResponse
} from "@/api/interfaces/IApi";
import {
  ILocalizationsData,
  ILocalizationsResponse
} from "@/api/interfaces/localizations/ILocalizations";
import {
  ICreateLocalizationDto,
  IUploadLocalizationImageDto
} from "./localizationsTypes";

export const localizationsService = {
  async fetchLocalizations(
    request: apiRequestType,
    query: IBaseQuery
  ): Promise<ILocalizationsData> {
    const queryString: string = queryObjectToUrlString(query);
    const response: IApiResponse = await request(
      `/localizations/getAll?${queryString}`,
      "get"
    );
    const { results, total }: ILocalizationsResponse = <ILocalizationsResponse>(
      response.data
    );
    return {
      ...response,
      results,
      total
    };
  },

  async uploadLocalizationImage(
    request: apiRequestFileType,
    payload: IUploadLocalizationImageDto
  ): Promise<IGApiResponse<{ url: string }>> {
    const res = await request(
      `/localizations/upload-image`,
      "post",
      payload.image,
      "file"
    );

    const data = res.data as { url: string };
    return { ...res, data };
  },

  async updateLocalization(
    request: apiRequestType,
    payload: ICreateLocalizationDto,
    id?: number,
    method?: "post" | "patch"
  ) {
    const { short_name, full_name, images, active = false } = payload;
    const resultPayload = { ...images, short_name, full_name, active };

    let url = `/localizations`;
    if (method === "patch" && id) {
      url = `/localizations/edit-localization/${id}`;
    }

    return await request(url, method || "post", resultPayload);
  },

  async removeLocalization(request: apiRequestType, id: number) {
    return await request(`/localizations/remove/${id}`, "delete");
  }
};
