import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  apiRequestType,
  httpMethodType,
  IApiResponse,
  IBaseQuery,
} from "@/api/interfaces/IApi";
import { IAboutLocation, IAboutLocationResponse } from "@/api/interfaces/about-locations/aboutLocation";

export const aboutLocationService = {
  async fetchAboutLocation(
    request: apiRequestType,
  ): Promise<IAboutLocationResponse> {
    const response: IAboutLocationResponse = <IAboutLocationResponse> await request(
      `/about-locations/first`,
      "get"
    );
    return {
      ...response,
    };
  },

  async actionAboutLocation(
    request: apiRequestType,
    data: IAboutLocation,
    method: httpMethodType,
    id: number | undefined
  ): Promise<IApiResponse> {
    return await request(
      `/about-locations${id ? "/" + id : ""}`,
      method,
      data
    );
  },
};
