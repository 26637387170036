import { IEsimPlansResponse } from "@/api/interfaces/esim-plans/common";
import {
  apiRequestType,
  IApiResponse,
  IBaseQuery
} from "@/api/interfaces/IApi";
import { editEsimPlanEsimServerDto } from "@/hooks/esim-plans/modules/updatePlanEsimServer";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import { IZone } from "../packages/esim/location-packages/types";
import { UpdatePlanDiscountDto } from "@/hooks/esim-plans/types/useEsimPlansListType";

export const plansService = {
  async fetchEsimPlans(
    request: apiRequestType,
    query: IBaseQuery,
    filter: string,
    reqUrl: string
  ): Promise<IApiResponse> {
    const reqQuery = queryObjectToUrlString({ ...query, filter });
    const response = await request(
      `/esim-directory/new/${reqUrl}?${reqQuery}`,
      "get"
    );

    const { results, total } = <IEsimPlansResponse>response.data;
    return {
      ...response,
      data: results,
      total
    };
  },

  async updatePlanStatus(
    request: apiRequestType,
    id: number,
    status: boolean,
    reqUrl: string
  ): Promise<IApiResponse> {
    return await request(`/esim-directory/${reqUrl}/${id}`, "patch", {
      active: status
    });
  },

  async updatePlanOperatorStatus(
    request: apiRequestType,
    id: number,
    status: boolean,
    value: string
  ): Promise<IApiResponse> {
    const query = queryObjectToUrlString({ location: value });
    return await request(
      `carriers/plan-operator/status/${id}?${query}`,
      "patch",
      {
        active: status
      }
    );
  },
  async changePlanEsimZone(
    request: apiRequestType,
    countryPlanId: number,
    zone: IZone
  ): Promise<IApiResponse> {
    return await request(`esim-directory/esim-zones/addToPlan`, "patch", {
      countryPlanId,
      zone
    });
  },
  async changePlanEsimServer(
    request: apiRequestType,
    data: editEsimPlanEsimServerDto,
    value: string
  ): Promise<IApiResponse> {
    const query = queryObjectToUrlString({ location: value });
    return await request(
      `esim-directory/esim-types-server/addToPlan?${query}`,
      "patch",
      data
    );
  },
  async changeDiscount(
    request: apiRequestType,
    data: UpdatePlanDiscountDto
  ): Promise<IApiResponse> {
    const { type, planId, discountAmount, discount } = data;
    return await request(`esim-directory/discount/${type}/${planId}`, "patch", {
      discount,
      discountAmount
    });
  }
};
